import React, { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ParticipantCard from "./ParticipantCard";
import StepCard from "./StepCard";
import axios from "axios";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import HostCard from "./HostCard";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";

const Invite = () => {
  const { id } = useParams();
  const [t] = useTranslation("global");
  const { setHeaderTitle } = useHeaderTitle();

  const [meeting, setMeeting] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [startTime1, setStartTime1] = useState(null);
  const [time, setTime] = useState(null);

  
  useEffect(() => {
    const getMeeting = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/get-meeting/${id}`
          // , {
          //   headers: {
          //     Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          //   },
          // }
        );
        if (response.status) {
        setHeaderTitle({titleText:" "})

          const steps = response.data.data?.steps;
          const time = response?.data?.data?.start_time;
          setMeeting(response.data?.data);
          setTime(response?.data?.data?.start_time);
          // Calculate the total count2
          const totalCount2 = steps?.reduce(
            (acc, step) => acc + step.count2,
            0
          );
          console.log("totalCount2: ", totalCount2);
          // Convert start time to a Date object
          const [hours, minutes, seconds] = time.split(":").map(Number);
          const startDate = new Date();
          startDate.setHours(hours);
          startDate.setMinutes(minutes);
          startDate.setSeconds(seconds);

          // Add totalCount2 minutes to the start date
          startDate.setMinutes(startDate.getMinutes() + totalCount2);

          // Format the new time
          const formattedTimeAfterAddingStepsTime =
            startDate.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
          setStartTime1(formattedTimeAfterAddingStepsTime);
        }
      } catch (error) {
        console.log("error while fetching meeting data", error);
      } finally {
        setIsLoading(false);
      }
    };
    getMeeting();
  }, [id]);

  const date = new Date(meeting?.date);
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  //   const time = new Date(meeting?.start_time);
  //   const formattedTime = time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  const startTime = meeting?.start_time;
  const formattedTime = new Date(`1970-01-01T${startTime}`).toLocaleTimeString(
    "en-US",
    { hour: "2-digit", minute: "2-digit", hour12: true }
  );

  // // Calculate the total count2
  // const totalCount2 = meeting?.steps?.reduce(
  //   (acc, step) => acc + step.count2,
  //   0
  // );
  // console.log("totalCount2: ", totalCount2);
  // // Convert start time to a Date object
  // // Convert start time to a Date object
  // const [hours, minutes, seconds] = time.split(":").map(Number);
  // const startDate = new Date();
  // startDate.setHours(hours);
  // startDate.setMinutes(minutes);
  // startDate.setSeconds(seconds);

  // // Add totalCount2 minutes to the start date
  // startDate.setMinutes(startDate.getMinutes() + totalCount2);

  // // Format the new time
  // const formattedTimeAfterAddingStepsTime = startDate.toLocaleTimeString(
  //   "en-US",
  //   {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     hour12: true,
  //   }
  // );
  return (
    <>
      {isLoading ? (
        <Spinner
          animation="border"
          role="status"
          className="center-spinner"
        ></Spinner>
      ) : (
        <div className="invite w-100">
          <div className="gradient-header">
            <svg
              width="100%"
              height="338"
              viewBox="0 0 1453 338"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_902_7997)">
                <rect
                  width="100%"
                  height="271"
                  fill="url(#paint0_linear_902_7997)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_f_902_7997"
                  x="-66.5"
                  y="-66.5"
                  width="1573"
                  height="404"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="33.25"
                    result="effect1_foregroundBlur_902_7997"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_902_7997"
                  x1="856"
                  y1="281.934"
                  x2="863.131"
                  y2="-138.913"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                  <stop offset="1" stop-color="#76C3EE" />
                </linearGradient>
              </defs>
            </svg>

            {/* <div className="gradient-header-child">
              <span className="gradient-text">
                TEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIM
              </span>
            </div>
            <div className="gradient-header-child-2">
              <span className="gradient-text">
                TEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIM
              </span>
            </div>
            <div className="gradient-header-child-3">
              <span className="gradient-text">
                TEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIMETEKTIM
              </span>
            </div> */}
          </div>
          <div className="content">
            <div className="row" style={{ height: "35vh" }}>
              <div className="col-md-8 d-flex flex-column">
                {/* <img
                src="/Assets/tektime-logo-invite.svg"
                width="40px"
                height="40px"
                alt=""
              /> */}
                {/* <span className="content-span mt-3">TekTime</span> */}
                <h5 className="content-heading fw-bold">
                  {meeting?.objective}
                </h5>
                {/* <span className="content-sub-heading">
                  {meeting?.objective}
                </span> */}
                {/* <span className="paragraph">{meeting?.description}</span> */}
              </div>

              <div className="col-md-4">
                <div className="position-relative">
                  {/* <HostCard data={meeting?.user} /> */}
                  <Card className="mt-3 host-card">
                    {/* <span
                      className="text-center"
                      style={{ color: "#92929D", fontSize: "12px" }}
                    >
                      Host
                    </span> */}
                    <Card.Body>
                      <div className="d-flex justify-content-center">
                        <div className="profile-logo">
                          <Card.Img
                            className="avatar-img"
                            // src="/Assets/avatar.jpeg"
                            // src={
                            //   meeting?.user?.image
                            //     ? Assets_URL + meeting?.user?.image
                            //     : "/Assets/avatar.jpeg"
                            // }
                            src={
                              meeting?.user?.enterprise?.logo
                                ? Assets_URL + meeting?.user?.enterprise?.logo
                                : "/Assets/logo2.png"
                            }
                          />
                          {/* <Card.Img
                            className="logout-icon"
                            // src="/Assets/Avatar_company.svg"
                            src={
                              meeting?.user?.enterprise?.logo
                                ? Assets_URL + meeting?.user?.enterprise?.logo
                                : "/Assets/Avatar_company.svg"
                            }
                            height="40px"
                            width="40px"
                            alt=""
                          /> */}
                        </div>
                      </div>

                      <Card.Title className="text-center mt-3 card-heading">
                        {/* {meeting?.user?.name + " " + meeting?.user?.last_name} */}
                        {meeting?.user?.enterprise?.name}
                      </Card.Title>
                      {/* <Card.Subtitle className="mb-2 card-subtext">
                        {meeting?.user?.post} -{" "}
                        {meeting?.user?.teams?.map((item) => item.name)}
                      </Card.Subtitle> */}
                    </Card.Body>
                  </Card>
                </div>
              </div>

              {/* ------------------------------------------------ HOST */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <h5 className="content-heading-title">{meeting?.title}</h5>
                <div className="d-flex align-items-center gap-2 content-body">
                  <div className="d-flex align-items-center gap-2">
                    <img src="/Assets/invite-date.svg" />

                    <span className="fw-bold formate-date">
                      {formattedDate}
                    </span>
                    {/* <span className="fw-bold formate-date">
                      {formattedTime}
                    </span> */}
                    {/* <span className="fw-bold formate-date">{startTime1}</span> */}
                    <span className="fw-bold formate-date">
                      {formattedTime}
                    </span>
                  </div>

                  {/* <div className="link d-flex gap-2 mt-3"> */}
                  <div className="d-flex align-items-center gap-2 content-meet-section">
                    <img src="/Assets/invite-link.svg" alt="" />
                    <span className="fw-bold formate-date">Google Meet</span>
                    <Link
                      to={meeting?.meet_link}
                      style={{ color: "#3873CC" }}
                      className="formate-link"
                    >
                      {meeting?.meet_link}
                    </Link>
                  </div>
                </div>
                <div className="paragraph-parent">
                  <span className="paragraph">{meeting?.description}</span>
                </div>
              </div>
            </div>
            <div className="cards-section">
              <div style={{ marginTop: "5rem" }}>
                <span className="participant-heading">Guide</span>
                <HostCard data={meeting?.user} />
              </div>
              {/* ------------------------------------------------ Participants */}

              {meeting?.participants.length > 1 && (
                <div style={{ marginTop: "5rem" }}>
                  <span className="participant-heading">Participants</span>
                  <div className="row">
                    <div className="col-md-3">
                      <ParticipantCard />
                    </div>
                    <div className="col-md-3">
                      <ParticipantCard />
                    </div>
                    <div className="col-md-3">
                      <ParticipantCard />
                    </div>
                    <div className="col-md-3">
                      <ParticipantCard />
                    </div>
                  </div>

                  <div>
                    <ParticipantCard data={meeting?.participants} />
                  </div>
                </div>
              )}

              {/* ------------------------------------------------ Steps */}

              <div style={{ marginTop: "5rem" }}>
                <span className="participant-heading">
                {`${t("meeting.newMeeting.labels.Summary")} `}

                </span>
                {/* <StepCard data={meeting?.steps} startTime={formattedTime} users={{ ...meeting?.user, image: meeting?.user?.image || '/Assets/avatar.jpeg' }}  /> */}
                <StepCard
                meeting={meeting}
                data={meeting?.steps}
                  startTime={formattedTime}
                  users={{
                    ...meeting?.user,
                    firstName: meeting?.user?.name,
                    lastName: meeting?.user?.last_name,
                    image:
                      meeting?.user?.assigned_to_image ||
                      meeting?.user?.image
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Invite;
