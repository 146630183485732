import React from "react";
import { Card } from "react-bootstrap";
import { Assets_URL } from "../../Apicongfig";

const HostCard = ({ data }) => {
  return (
    <Card style={{ width: "310px" }} className="mt-4 participant-card">
      <Card.Body>
        <div className="d-flex justify-content-center">
          <div className="profile-logo">
            <Card.Img className="user-img" 
            // src="/Assets/avatar.jpeg" 
            
            src={
              data?.image
                ? Assets_URL + data?.image
                : data?.participant_image
            }
            />
            <Card.Img
              className="logout-icon"
              src="/Assets/Avatar_company.svg"
              height="20px"
              width="20px"
              alt="tektime"
            />
          </div>
        </div>

        <Card.Title className="text-center mt-4 card-heading">
          {/* Mohamed Moussa */}
          {data?.name + " " + data?.last_name}
        </Card.Title>
        <Card.Subtitle className="mb-2 card-subtext">
          {data?.post} - {data?.teams?.map((item) => item.name)}
        </Card.Subtitle>
        {/* <Card.Text className="card-content">
          Lorem ipsum dolor sit amet consectetur. Sit nullam consequat eu neque
          amet. Suspendisse sed orci arcu arcu. Feugiat turpis viverra euismod
          at leo quis quam. Pulvinar in dui dolor sit in.
        </Card.Text> */}
      </Card.Body>
    </Card>
  );
};

export default HostCard;
