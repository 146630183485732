// PrivacyPolicy.js

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div
      className="container-fluid text-justify"
      style={{ padding: "0px 24px" }}
    >
      <h1>TekTIME Privacy Policy</h1>

      <div className="privacy-content">
        <h2>Introduction</h2>
        <p>
          Welcome to TekTIME. We are committed to protecting and respecting your
          privacy. This privacy policy explains how we collect, use, disclose,
          and protect your personal information when you use our TekTIME SaaS
          software.
        </p>
        <div className="d-flex">
          <p>
            <span
              style={{
                fontWeight: 500,
                fontSize: "1.5rem",
              }}
            >
              Google API Services Disclosure:
            </span>{" "}
            TekTIME’s use and transfer to any other app of information received
            from Google APIs will adhere to the Google API Services User Data
            Policy, including the Limited Use requirements. {" "}
             <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              target="_blank"
            >
               Link to Google API Services User Data Policy
            </a>
          </p>
        </div>
        <div className="first">
          <h2>1. Information We Collect</h2>
          <p>
            We collect several types of information for various uses to provide
            and improve our service to you.
          </p>

          <div>
            {/* <h5 className="mx-5">1.1. Personal Information</h5> */}
            <p>
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "1.5rem",
                }}
              >
                1.1. Personal Information:{" "}
              </span>
              When you register with TekTIME, we may ask you to provide us with
              certain personally identifiable information which may include, but
              is not limited to:
            </p>
            <ul>
              <li>First and last name</li>
              <li>Email address</li>
            </ul>
          </div>
        </div>

        <div>
          {/* <h5 className="mx-5">1.2. Données de journal</h5> */}
          <p>
            <span
              style={{
                fontWeight: 500,
                fontSize: "1.5rem",
              }}
            >
              1.2. Log Data:{" "}
            </span>
            We collect information that your browser sends whenever you visit
            our Service (“Log Data”). This Log Data may include information such
            as your computer's IP address, browser type, browser version, the
            pages of our Service that you visit, the time and date of your
            visit, the time spent on these pages, and other statistics.
          </p>
        </div>
        <div>
          {/* <h5 className="mx-5">1.2. Données de journal</h5> */}
          <p>
            <span
              style={{
                fontWeight: 500,
                fontSize: "1.5rem",
              }}
            >
              1.3. Google Calendar Data:{" "}
            </span>
            To provide our services, we may request access to your Google
            Calendar. This includes viewing and managing your calendar events to
            ensure seamless integration and improved user experience within
            TekTIME. We use the following sensitive scopes:
          </p>
          <ul>
            <li>https://www.googleapis.com/auth/calendar</li>
            <li>https://www.googleapis.com/auth/calendar.events</li>
          </ul>
        </div>

        <h2>2. Use of Information</h2>
        <p>
          We use the information we collect in a variety of ways, including to:
        </p>
        <ul>
          <li>Provide and maintain our service</li>
          <li>Notify you of changes to our service</li>
          <li>
            Allow you to participate in the interactive features of our service
            when you wish
          </li>
          <li>Provide customer support</li>
          <li>
            Collect analytics or valuable information so that we can improve our
            service
          </li>
          <li>Monitor the use of our service</li>
          <li>Detect, prevent, and resolve technical problems</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties.
        </p>
        <p>We may share your information in the following situations:</p>
        <ul>
          <li>
            With third-party service providers to facilitate our Service, to
            provide the Service on our behalf, to perform Service-related
            services, or to assist us in analyzing how our Service is used.
          </li>
          <li>
            To comply with a legal obligation, to protect and defend our rights
            or property, to prevent or investigate possible wrongdoing in
            connection with the Service, or to protect the personal safety of
            users of the Service or the public.
          </li>
        </ul>

        <h2>4. Data Security</h2>
        <p>
          The security of your data is important to us. We implement security
          measures to protect your personal information. However, please note
          that no method of transmission over the Internet or method of
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </p>

        <h2>5. Your Rights</h2>
        <p>
          In accordance with the Data Protection Act and the GDPR, you have the
          following rights:
        </p>

        <ul>
          <li>
            <span style={{ fontWeight: 500 }}>Access : </span> You have the
            right to request a copy of the information we hold about you.
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Rectification : </span> You have
            the right to request that we correct any information that you
            believe is inaccurate or incomplete.
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Erasure : </span> You have the
            right to request the deletion of your personal data under certain
            conditions.
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Limitation : </span> You have the
            right to request the limitation of the processing of your personal
            data under certain conditions.
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Opposition : </span> You have the
            right to object to the processing of your personal data under
            certain conditions.
          </li>
          <li>
            <span style={{ fontWeight: 500 }}>Portability : </span> You have the
            right to request that we transfer the data we have collected to
            another organization, or directly to you, under certain conditions.
          </li>
        </ul>

        <h2>6. Changes to This Privacy Policy </h2>
        <p>
          We may update our privacy policy from time to time. We will notify you
          of any changes by posting the new privacy policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when posted on this page.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
