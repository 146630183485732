import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GoPlusCircle } from "react-icons/go";
import { AiFillDelete } from "react-icons/ai";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { FaUserCircle } from "react-icons/fa";
import CounterContainer from "./PlayMeeting/components/CounterContainer";
import { useCounterContext } from "./context/CounterContext";
import { useTranslation } from "react-i18next";
import { IoMdEye } from "react-icons/io";
import { MdOutlineModeEdit } from "react-icons/md";
import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";
import { image_upload_handler_callback, optimizeEditorContent } from "./Chart";
import ShowIF from "../../Utils/ShowIF";
// import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Button, Modal, ProgressBar } from "react-bootstrap";
// import { QueueFunction } from "../../../hooks/useSpeechToText/QueueFunction";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useDraftMeetings } from "../../../context/DraftMeetingContext";

const Play = ({ props }) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const editorRef = useRef(null);
  const TINYMCEAPI = process.env.REACT_APP_TINYMCE_API;
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    meetingData,
    savedTime,
    negativeTimes,
    activeStepIndex,
    setNextActiveStep,
    setPreviousActiveStep,
  } = useCounterContext();
  const [inputData, setInputData] = useState([]);
  const [stepData, setStepData] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [stepTitle1, setStepTitle1] = useState("");
  const currentDate = new Date();
  const inputDate = new Date(inputData.date);
  const currentDateTime = moment(currentDate);
  const inputDateTime = moment(inputDate);
  const [stepsState, setStepsState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [stepNotes, setStepNotes] = useState([]);
  const [decision, setDecision] = useState([]);
  const [real_start_time, setRealStartTime] = useState("");
  const [buttonText, setButtonText] = useState(t("Close"));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showStepContentEditor, setShowStepContentEditor] = useState(false);
  // REACT QUILL EDITORS for Notes and Decisions:
  const [notesEditor, setNotesEditor] = useState({
    value: "",
    showEditor: false,
  });
  const [decisionEditor, setDecisionEditor] = useState({
    value: "",
    showEditor: false,
  });
  const [planDActionEditor, setPlanDActionEditor] = useState({
    showEditor: false,
  });
  const [isAutomatic, setIsAutomatic] = useState(false);
  const [previousSteps, setPreviousSteps] = useState([]);
  const [myStepNoteId, setMyStepNoteId] = useState(null);
  const { updateLanguage, language } = useDraftMeetings();

  const getMeeting = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/meetings/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status) {
        const meetingData = response.data?.data;
        // Reorder steps array to move the step with "in_progress" status to the beginning
        // if (meetingData && meetingData.steps) {
        //   const inProgressStepIndex = meetingData.steps.findIndex(step => step.status === "in_progress");
        //   if (inProgressStepIndex !== -1) {
        //     const inProgressStep = meetingData.steps.splice(inProgressStepIndex, 1)[0];
        //     meetingData.steps.unshift(inProgressStep);
        //   }
        //   console.log("inprogress step index",response.data?.data?.steps.findIndex(step=>step.status === 'in_progress'))
        //   // setCurrentStepIndex(inProgressStepIndex);
        // }

        const inProgressIndex = response.data?.data?.steps?.findIndex(
          (step) => step.status === "in_progress"
        );
        console.log("inProgressIndex", inProgressIndex);
        if (inProgressIndex !== -1) {
          setCurrentStepIndex(inProgressIndex);
        }
        // Filter out steps with null or empty notes
        const filteredSteps = meetingData?.steps?.filter(
          (step) => step.note && step.note.trim() !== ""
        );

        if (response.data.data?.prise_de_notes === "Automatic") {
          setIsAutomatic(true);
        } else {
          setIsAutomatic(false);
        }
        // setHtmlString(
        //   response.data?.data?.steps[currentStepIndex]?.editor_content
        // );
        setPreviousSteps(meetingData?.steps);
        setStepNotes(meetingData?.steps?.map((step) => step?.note));
        setDecision(meetingData?.steps?.map((step) => step?.decision));
        setStepData(response.data?.data?.steps);
        setTableData(response.data?.data?.plan_d_actions || []);
        setInputData(response?.data?.data);
        // setFirstStepId(response?.data?.data?.steps[0]?.id);
        setLoading(false);
        return response.data?.data;
      }
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  if (!browserSupportsSpeechRecognition) {
    console.log("browser is not supported for speech recognition");
  }
  useEffect(() => {
    getMeeting();
  }, [id]);

  useEffect(() => {
    setRealStartTime(moment().format("HH:mm:ss"));
    const fetchMeetingData = async () => {
      try {
        const currentTime = moment().format("HH:mm:ss");
        const meetingData = await getMeeting();
        const updatedMeetingData = {
          ...meetingData,
          real_start_time: currentTime,
          _method: "put",
        };

        const response = await axios.post(
          `${API_BASE_URL}/meetings/${id}`,
          updatedMeetingData,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          //   const meetingData = response.data?.data
          //   if (meetingData && meetingData.steps) {
          //     const inProgressStepIndex = meetingData.steps.findIndex(step => step.status === "in_progress");
          //     if (inProgressStepIndex !== -1) {
          //       const inProgressStep = meetingData.steps.splice(inProgressStepIndex, 1)[0];
          //       meetingData.steps.unshift(inProgressStep);
          //     }
          //     console.log("inprogress step index",response.data?.data?.steps.findIndex(step=>step.status === 'in_progress'))
          //     // setCurrentStepIndex(inProgressStepIndex);
          //   }
          // console.log("inprogress step index dosri wali",response.data?.data?.steps.findIndex(step=>step.status === 'in_progress'))
          // const inProgressIndex = response.data?.data?.steps?.findIndex(
          //   (step) => step.status === "in_progress"
          // );
          // console.log("inProgressIndex", inProgressIndex);
          // if (inProgressIndex !== -1) {
          //   setCurrentStepIndex(inProgressIndex);
          // } else{
          //   setCurrentStepIndex(0);
          // }
          // setCurrentStepIndex(response.data?.data?.steps.findIndex(step=>step.status === 'in_progress'))
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    fetchMeetingData(); // Call the async function inside useEffect
  }, [id]);

  useEffect(() => {
    const initialState = stepData?.map((step) => ({
      remainingTime: step.time * 60, // Initialize with the duration in seconds
      isPlaying: false,
      pausedTime: null,
    }));
    setStepsState(initialState);
  }, [stepData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setStepsState((prevState) => {
        if (!Array.isArray(prevState)) {
          // If not, initialize it as an empty array
          prevState = [];
        }
        const updatedState = [...prevState];
        const currentStep = updatedState[currentStepIndex];

        // Automatically play when navigating to a step
        if (inputData && currentStep && !currentStep.isPlaying) {
          updatedState[currentStepIndex] = {
            ...currentStep,
            isPlaying: true,
            pausedTime: Date.now(),
          };
        }
        if (
          currentStep &&
          currentStep.isPlaying &&
          currentStep.remainingTime > 0
        ) {
          updatedState[currentStepIndex] = {
            ...currentStep,
            remainingTime: currentStep.remainingTime,
          };
        }
        return updatedState;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [currentStepIndex]);

  const [delay, setDelay] = useState("");

  useEffect(() => {
    if (currentDateTime.isSame(inputDateTime, "day")) {
      const startTime = moment(inputData.start_time, "HH:mm");
      const timeDifferenceInMillis = Math.abs(currentDateTime - startTime);
      const duration = moment.duration(timeDifferenceInMillis);

      if (currentDateTime.isSame(startTime)) {
        setDelay("");
      } else {
        const formattedTime = `${t(
          `The meeting started with`
        )} \n${duration.minutes()} minutes ${
          currentDateTime.isAfter(startTime)
            ? `${t("delay")}`
            : `${t("in advance")}`
        }.`;

        if (duration.asMinutes() < 1) {
          setDelay("");
        } else {
          setDelay(formattedTime);
        }
      }
    }
  }, [inputData.start_time]);

  useEffect(() => {
    if (inputData && inputData.steps && inputData.steps.length > 0) {
      if (
        inputData.steps[currentStepIndex] &&
        inputData.steps[currentStepIndex].time
      ) {
      }
      setStepTitle1(
        currentStepIndex < inputData?.steps?.length
          ? inputData?.steps[currentStepIndex]?.title || ""
          : ""
      );
    }
  }, [inputData, currentStepIndex]);

  const [playMeetingTime, setPlayMeetingTime] = useState("");
  const [endMeetingTime, setEndMeetingTime] = useState("");

  useEffect(() => {
    setPlayMeetingTime(moment().format("HH:mm"));
    return () => {
      setEndMeetingTime(moment().format("HH:mm"));
    };
  }, []);

  const handlePlayPause = (index) => {
    const updatedStepsState = [...stepsState];
    const currentStep = updatedStepsState[index];

    if (currentStep.isPlaying) {
      // Pausing the timer
      const pausedTime = new Date().getTime();
      const pausedDurationInSeconds = Math.floor(
        (pausedTime - currentStep.pausedTime) / 1000
      );

      updatedStepsState[index] = {
        ...currentStep,
        isPlaying: false,
        remainingTime: Math.max(
          currentStep.remainingTime - pausedDurationInSeconds,
          0
        ),
        pausedTime,
      };
    } else {
      // Resuming the timer
      updatedStepsState[index] = {
        ...currentStep,
        isPlaying: true,
        pausedTime: new Date().getTime(),
      };
    }
    setStepsState(updatedStepsState);
  };

  const [isNext, setIsNext] = useState(false);
  const handlenextPage = async (val) => {
    if (currentStepIndex < inputData.steps.length - 1) {
      setIsNext(true);
      handlePlayPause(currentStepIndex, false);
      setButtonDisabled(true);
      // setShowNextCounter(false);
      const currentStep = inputData.steps[currentStepIndex];
      const stepId = currentStep.id;
      const optimizedEditorContent = await optimizeEditorContent(
        currentStep?.editor_content
      );

      const postData = {
        // editor_content: optimizedEditorContent ? optimizedEditorContent : null,
        editor_content: optimizedEditorContent || "",
        // savedTime: savedTime == 0 ? 0 : savedTime,
        // negative_time:
        //   negativeTimes[activeStepIndex] != 0
        //     ? negativeTimes[activeStepIndex]
        //     : 0,
        savedTime: savedTime != 0 ? savedTime : 0,
        negative_time: savedTime == 0  ? (negativeTimes[activeStepIndex] != 0 ? negativeTimes[activeStepIndex] : 0) : 0,
        
        totalstepnotes: stepNotes[currentStepIndex],
        totaldecision: decision.join(" "),
        note: stepNotes[currentStepIndex],
        status: "active",
        url: inputData.steps[currentStepIndex].url
          ? inputData.steps[currentStepIndex].url
          : null,
        meeting_id: id,
        decision: decision[currentStepIndex],
        actions: tableData ? tableData : [],
      };

      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.post(
          `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ` Bearer ${token}`,
            },
          }
        );
        if (response.status) {
          getMeeting();
          setTableData(response.data.data?.planDActions); // Set the new actions for the current step
          // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
        }
      } catch (error) {
        // toast.error(error.response?.data?.message);
      }
      setIsNext(false);
      setCurrentStepIndex((prevIndex) => prevIndex + 1);
      setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
      setButtonDisabled(false);
      setNextActiveStep();
    }
    return;
  };

  const [isPrevious, setIsPrevious] = useState(false);

  const previousPage = async () => {
    if (currentStepIndex > 0) {
      setIsPrevious(true);
      handlePlayPause(currentStepIndex, false);
      const currentStep = inputData.steps[currentStepIndex];
      const optimizedEditorContent = await optimizeEditorContent(
        currentStep?.editor_content
      );
      const stepId = currentStep.id;

      const postData = {
        editor_content: optimizedEditorContent ? optimizedEditorContent : null,
        totalstepnotes: stepNotes[currentStepIndex],
        note: stepNotes[currentStepIndex],
        totaldecision: decision.join(" "),
      // savedTime: savedTime == 0 ? 0 : savedTime,
        // negative_time:
        //   negativeTimes[activeStepIndex] != 0
        //     ? negativeTimes[activeStepIndex]
        //     : 0,
        savedTime: savedTime != 0 ? savedTime : 0,
        negative_time: savedTime == 0  ? (negativeTimes[activeStepIndex] != 0 ? negativeTimes[activeStepIndex] : 0) : 0,
        status: "active",
        meeting_id: id,
        url: inputData.steps[currentStepIndex].url
          ? inputData.steps[currentStepIndex].url
          : null,
        decision: decision[currentStepIndex],
        actions: tableData ? tableData : [],
      };
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.post(
          `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: ` Bearer ${token}`,
            },
          }
        );
        if (response.status) {
          getMeeting();
          const createdActions = response.data.data.planDActions; // Assuming the response contains the new actions
          setTableData(createdActions); // Set the new actions for the current step
          // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
        }
        setCurrentStepIndex((prevIndex) => prevIndex - 1);
        // setTableData([]);
        setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
        setIsPrevious(false);
        setPreviousActiveStep();
      } catch (error) {
        // console.log("error", error);
        setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
        // toast.error(error.response.data.message);
      }
    }
  };

  // FUNCTION TO SAVE THE EDITOR CONTENT of the current step.
  const saveEditorContent = async (editorContent) => {
    const _OPTIMIZED_EDITOR_CONTENT = await optimizeEditorContent(
      editorContent
    );
    const stepId = inputData.steps[currentStepIndex].id;
    const URL = `${API_BASE_URL}/play-meetings/steps/${stepId}`;
    const postData = {
      ...inputData.steps[currentStepIndex],
      editor_content: _OPTIMIZED_EDITOR_CONTENT,
    };
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(URL, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${token}`,
        },
      });
      if (response.status) {
        // toast.success(response.data?.message);
        toast.success("Content saved successfully");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // const [lastStepNote, setLastStepNote] = useState(null);
  const saveDataonEnd = async (val) => {
    handlePlayPause(currentStepIndex, false);
    setButtonDisabled(true);
    // setShowNextCounter(false);
    const currentStep = inputData?.steps[currentStepIndex];
    const stepId = currentStep.id;
    const postData = {
      savedTime: savedTime == 0 ? 0 : savedTime,
      negative_time:
        negativeTimes[activeStepIndex] != 0
          ? negativeTimes[activeStepIndex]
          : 0,
      totalstepnotes: stepNotes[currentStepIndex],
      note: stepNotes[currentStepIndex],
      totaldecision: decision.join(" "),
      decision: decision[currentStepIndex],
      url: inputData.steps[currentStepIndex].url
        ? inputData.steps[currentStepIndex].url
        : null,
      status: "active",
      meeting_id: id,
      actions: tableData ? tableData : [],
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        // setLastStepNote(response.data.data?.note);
        // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
        return response?.data?.data?.note;
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const closeMeeting = async () => {
    const responseNotes = await saveDataonEnd();
    const updatedStepData = [...stepData]; // Copy the stepData array
    updatedStepData[updatedStepData.length - 1].note = responseNotes; // Update the note for the last step
    setIsLoading(true);
    setButtonDisabled(true);
    localStorage.setItem("lastURL", "/play");
    // Parse the start time string
    const [hour, minute] = meetingData?.start_time?.split(":").map(Number);

    // Add one hour to the hour component
    let endHour = hour + 1;

    // If end hour is greater than or equal to 24, subtract 24
    if (endHour >= 24) {
      endHour -= 24;
    }

    // Format the end time as a string
    const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;

    console.log("endTime str->", endTimeStr);
    const updatedDatWithClosingTime = {
      ...meetingData,
      real_end_time: moment().format("HH:mm:ss"),
      real_start_time: real_start_time,
      _method: "put",
      plan_d_actions: tableData ? tableData : [],
      steps: updatedStepData,
      step_decisions: decision.filter((decision) => decision != ""),
      end_time: endTimeStr,
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}`,
        updatedDatWithClosingTime,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      // console.log("error", error);
    }
    // -------------------------------------------------- NOW UPDATE STATUS OF MEETING ----------------------------------------------
    const realEndTime = moment().format("HH:mm:ss");
    try {
      const postData = {
        real_end_time: realEndTime,
        status: "closed",
        _method: "put",
      };
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}/status`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      // console.log("meeting status api", response);
      if (response.status) {
        // console.log("meeting status changed successfully", response.data);
        setButtonDisabled(false);
        setIsLoading(false);
        navigate("/meeting");
      }
    } catch (error) {
      console.log("error ", error);
      setIsLoading(false);
    }
  };

  const updateMeetingStatus = async () => {
    const realEndTime = moment().format("HH:mm:ss");
    const userConfirmed = window.confirm(t("confirmation"));

    if (!userConfirmed) {
      return;
    }
    try {
      const postData = {
        // real_end_time: realEndTime,
        real_end_time: moment().format("HH:mm:ss"),
        status: "abort",
        _method: "put",
        plan_d_actions: tableData,
        // step_notes: stepNotes,
        step_notes: null,
        step_decisions: decision,
      };
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}/status`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        console.log("meeting status changed successfully", response.data);
        // toast.success(response.data?.message);
        navigate("/meeting");
        //
      }
    } catch (error) {
      // console.log("error ", error);
    }
  };

  // const [isExit, setIsExit] = useState(false);
  // const exit = () => {
  //   console.log("clicked");
  //   setIsExit(true);
  //   recorderControls.stopRecording();
  // };

  // const ExitMeeting = async (blob) => {
  //   console.log("chl");
  //   const currentStep = inputData.steps[currentStepIndex];
  //   const stepId = currentStep.id;
  //   const formData = new FormData();
  //   const file = new File([blob], "user_audio.webm", {
  //     type: "audio/webm",
  //   });
  //   // console.log("file", file);
  //   const userId = sessionStorage.getItem("user_id");
  //   formData.append("audio_file", file);
  //   formData.append("step_id", stepId);
  //   formData.append("meeting_id", id);
  //   formData.append("user_id", userId);
  //   axios
  //     .post(`${API_BASE_URL}/audio-route`, formData)
  //     .then(async (response) => {
  //       // recorderControls.stopRecording();
  //       if (recorderControls) {
  //         recorderControls.stopRecording();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  //   recorderControls.stopRecording();
  //   const realEndTime = moment().format("HH:mm:ss");
  //   handlePlayPause(currentStepIndex, false);
  //   setButtonDisabled(true);
  //   // setShowNextCounter(false);
  //   const optimizedEditorContent = await optimizeEditorContent(
  //     currentStep?.editor_content
  //   );

  //   try {
  //     const postData = {
  //       // real_end_time: realEndTime,
  //       status: "in_progress",
  //       // _method: "put",
  //       editor_content: optimizedEditorContent ? optimizedEditorContent : null,
  //       savedTime: savedTime == 0 ? 0 : savedTime,
  //       negative_time:
  //         negativeTimes[activeStepIndex] != 0
  //           ? negativeTimes[activeStepIndex]
  //           : 0,
  //       totalstepnotes: null,
  //       totaldecision: decision.join(" "),
  //       note: null,
  //       decision: decision[currentStepIndex],
  //       actions: tableData ? tableData : [],
  //       meeting_id: id,
  //     };
  //     const response = await axios.post(
  //       `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
  //       postData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       console.log("meeting status changed successfully", response.data);
  //       // toast.success(response.data?.message);
  //       navigate("/meeting");
  //     }
  //   } catch (error) {
  //     // console.log("error ", error);
  //   }
  // };

  const GradientSvg = (
    <svg height="0px">
      <defs>
        <linearGradient id="your-unique-id" x1="1" y1="0" x2="1" y2="1">
          <stop offset="10%" stopColor="#F2E358" />
          <stop offset="90%" stopColor="#CB690F" />
        </linearGradient>
      </defs>
    </svg>
  );
  const GradientSvg2 = (
    <svg height="0px">
      <defs>
        <linearGradient id="your-unique-id2" x1="1" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor="#F25861" />
          <stop offset="90%" stopColor="#CB0F1A" />
        </linearGradient>
      </defs>
    </svg>
  );
  const GradientSvg3 = (
    <svg height="0px">
      <defs>
        <linearGradient id="your-unique-id1" x1="1" y1="0" x2="1" y2="1">
          <stop offset="10%" stopColor="#CB0C17" />
          <stop offset="90%" stopColor="#5AAFD6" />
        </linearGradient>
      </defs>
    </svg>
  );

  const handleTableDataChange = (e, index) => {
    const { name, value } = e.target;
    setTableData((prevTableData) =>
      prevTableData?.map((rowData, i) =>
        i === index
          ? {
              ...rowData,
              [name]: value,
              // participant_id: newArray,
              step_id: inputData.steps[currentStepIndex].id,
              status: "Todo",
            }
          : rowData
      )
    );
  };

  const handleButtonClick = () => {
    if (Array.isArray(tableData) && tableData.length > 0) {
      setTableData([
        ...tableData,
        {
          order: 0,
          action: "",
          action_days: 0,
          participant_id: "",
          step_id: inputData.steps[currentStepIndex].id,
          status: "Todo",
        },
      ]);
    } else {
      setTableData([
        {
          order: 0,
          action: "",
          action_days: 0,
          participant_id: "",
          step_id: inputData.steps[currentStepIndex].id,
          status: "Todo",
        },
      ]);
    }
  };

  const handleButtonDelete = async (index) => {
    const actionToBeDeleted = tableData[index];
    const id = actionToBeDeleted.id;
    //Send API Call only if the action is already saved in the database.
    const foundInDatabase = actionToBeDeleted.id; // If the action is already saved in the database, it will have an id.
    //----API CALL TO DELETE ACTION
    if (foundInDatabase) {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/planDactions/${id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.clear();
        console.log("response", response);
      } catch (error) {
        console.log("error", error);
        return;
      }
    }

    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  function addIframesToLinks(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const links = Array.from(doc.querySelectorAll("a"));

    links.forEach((linkElement) => {
      const linkURL = linkElement.getAttribute("href");
      const iframe = createIframeForLink(linkURL);
      // Replace the link element with the iframe
      linkElement.parentNode.replaceChild(iframe, linkElement);
    });

    return doc.documentElement.outerHTML;
  }
  function createIframeForLink(linkURL) {
    const iframe = document.createElement("iframe");
    iframe.src = linkURL;
    iframe.width = "100%";
    // iframe.height = "500px";
    iframe.title = "Embedded Content";
    iframe.style.scrollSnapType = "none";
    iframe.style.border = "none";
    return iframe;
  }
  // Show preview of Links in Iframe:
  useEffect(() => {
    if (inputData && inputData?.steps && inputData?.steps?.length > 0) {
      // const originialHtml = inputData.steps[currentStepIndex].editor_content;
      const originialHtml =
        inputData?.steps[currentStepIndex]?.editor_content === null
          ? ""
          : inputData?.steps[currentStepIndex]?.editor_content;
      const modifiedHtml = addIframesToLinks(originialHtml);
      setInputData((prevData) => ({
        ...prevData,
        steps: prevData.steps?.map((step, index) => {
          if (index === currentStepIndex) {
            return {
              ...step,
              editor_content: modifiedHtml,
            };
          }
          return step;
        }),
      }));
    }
  }, [currentStepIndex]);

  const handleIncrementCount = (index) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, i) =>
        i === index
          ? {
              ...rowData,
              // action_days: Math.min(parseFloat(rowData.action_days) + 1, 5),
              action_days: Math.min(parseFloat(rowData.action_days) + 1, 100),
            }
          : rowData
      )
    );
  };

  const handleDecrementCount = (index) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, i) =>
        i === index
          ? {
              ...rowData,
              action_days: Math.max(parseFloat(rowData.action_days) - 1, 0),
            }
          : rowData
      )
    );
  };
  const [stepNoteEditor, setStepEditor] = useState({
    value: "",
    showEditor: false,
  });
  const [show, setShow] = useState({
    value: "",
    showEditor: false,
  });

  // ================>TEXT EDITORS TOGGLE FUNCTIONS: <====================
  const handleDecisionEditorToggle = () => {
    setNotesEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setDecisionEditor((prev) => {
      return {
        ...prev,
        showEditor: !prev.showEditor,
      };
    });
    setShowStepContentEditor(false);
  };

  const handleNotesEditorToggle = () => {
    setDecisionEditor((prev) => ({ ...prev, showEditor: false })); // Close the decision editor if it's open
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the step editor if it's open
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the all notes editor if it's open

    setNotesEditor((prev) => {
      return {
        ...prev,
        showEditor: !prev.showEditor,
      };
    });
    setShowStepContentEditor(false);
  };

  // Function to handle eye button click
  const handlePlanDActionEditor = () => {
    setDecisionEditor((prev) => ({ ...prev, showEditor: false }));
    setNotesEditor((prev) => ({ ...prev, showEditor: false }));
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close step notes editor if it's open
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the all notes editor if it's open
    setPlanDActionEditor((prev) => ({
      ...prev,
      showEditor: !prev.showEditor,
    }));

    // If closing the editor, update entered data
    if (planDActionEditor.showEditor) {
      const enteredDataString = tableData?.map((rowData) => ({
        action: rowData.action,
        action_days: rowData.action_days,
      }));
      // setEnteredData(enteredDataString);
    }
    setShowStepContentEditor(false);
  };

  const handleStepContentEditor = async () => {
    if (showStepContentEditor === true) {
      const optimizedEditorContent = optimizeEditorContent(
        inputData.steps[currentStepIndex]?.editor_content
      );
      await saveEditorContent(optimizedEditorContent);
    }
    setDecisionEditor((prev) => ({ ...prev, showEditor: false }));
    setNotesEditor((prev) => ({ ...prev, showEditor: false }));
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false }));
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the step editor if it's open
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the all notes editor if it's open
    setShowStepContentEditor((prev) => !prev);
  };

  const [editorData, setEditorData] = useState(true);
  const [editorContent, setEditorContent] = useState(true);
  const handleShow = async () => {
    setNotesEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setDecisionEditor((prev) => ({ ...prev, showEditor: false })); // Close the decision editor if it's open
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false })); // Close the plandAction editor if it's open
    setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the step editor if it's open
    setShow((prev) => {
      return {
        ...prev,
        showEditor: !prev.showEditor,
      };
    });
    setShowStepContentEditor(false);
    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );

    const postData = {
      editor_content: optimizedEditorContent || "",
      savedTime: savedTime == 0 ? 0 : savedTime,
      negative_time:
        negativeTimes[activeStepIndex] != 0
          ? negativeTimes[activeStepIndex]
          : 0,
      file: currentStep.file || "",
      url: currentStep.url || "",
      totalstepnotes: stepNotes[currentStepIndex],
      totaldecision: decision.join(" "),
      note: stepNotes[currentStepIndex],
      status: "active",
      meeting_id: id,
      decision: decision[currentStepIndex],
      actions: tableData ? tableData : [],
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        getMeeting();
        setTableData(response.data.data?.planDActions); // Set the new actions for the current step
        // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
      }
    } catch (error) {
      // toast.error(error.response?.data?.message);
    }
  };

  const [stepNote, setStepNote] = useState("");
  const [myId, setMyId] = useState(null);
  const [myStep, setMyStep] = useState({});
  const handleStepEditor = (id) => {
    setMyId(id);
    const getStep = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/steps/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        if (response.status) {
          setStepNote(response?.data?.data?.note);
          setMyStepNoteId(response?.data?.data?.id);
        }
      } catch (error) {
        console.log("error while fetching step", error);
      }
    };
    getStep();
    // setShow((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setNotesEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    setDecisionEditor((prev) => ({ ...prev, showEditor: false })); // Close the decision editor if it's open
    setPlanDActionEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open

    setStepEditor((prev) => {
      return {
        ...prev,
        showEditor: !prev.showEditor,
        // showEditor: false,
      };
    });
    setShowStepContentEditor(false);
  };
  const getStep = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/steps/${myId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      if (response.status) {
        setMyStep(response?.data?.data);
        setStepNote(response?.data?.data?.note);
        setMyStepNoteId(response?.data?.data?.id);
      }
    } catch (error) {
      console.log("error while fetching step", error);
    }
  };
  useEffect(() => {
    getStep();
  }, [myId, myStepNoteId]);

  const [isUpdated, setIsUpdated] = useState(false);
  const updateStepNote = async () => {
    setEditorContent(true);
    setIsUpdated(true);
    const currentStep = inputData.steps[currentStepIndex];

    console.log("mystep", myStep);
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );
    const postDataNote = {
      ...myStep,
      actions: meetingData?.steps?.plan_d_actions || null,
      // editor_content: optimizedEditorContent ? optimizedEditorContent : null,
      // savedTime: savedTime == 0 ? 0 : savedTime,
      // negative_time:
      //   negativeTimes[activeStepIndex] != 0
      //     ? negativeTimes[activeStepIndex]
      //     : 0,
      // totaldecision: decision.join(" "),
      // decision: decision[currentStepIndex],
      // actions: tableData ? tableData : [],
      totalstepnotes: stepNote,
      note: stepNote,
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${myStepNoteId}/step-note-and-action`,
        // show.showEditor ? postDataNote : postData,
        postDataNote,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        console.log(
          "response-> step note updated successfully",
          response.data.data
        );
        setIsUpdated(false);
        setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the step editor if it's open
        setShow((prev) => ({ ...prev, showEditor: false })); // Close the see all notes if it's open
        // setShowStepContentEditor(true)
        // setShow((prev) => {
        //   return {
        //     ...prev,
        //     showEditor: !prev.showEditor,
        //   };
        // });
        getMeeting();
      }
    } catch (error) {
      setStepEditor((prev) => ({ ...prev, showEditor: false })); // Close the notes editor if it's open
    }
  };

  // const [hasStartedRecording, setHasStartedRecording] = useState(false);
  // const recorderControls = useAudioRecorder(
  //   {
  //     noiseSuppression: true,
  //     echoCancellation: true,
  //   },
  //   (err) => console.table(err) // onNotAllowedOrFound
  // );

  // useEffect(() => {
  //   if (isAutomatic && !hasStartedRecording && recorderControls) {
  //     // Start recording automatically
  //     recorderControls.startRecording();
  //     setHasStartedRecording(true);
  //   }
  // }, [isAutomatic, recorderControls, hasStartedRecording]);

  // const handleNext = async () => {
  //   setIsLoading(true);
  //   handlePlayPause(currentStepIndex, false);
  //   // setShowNextCounter(false);
  //   const currentStep = inputData.steps[currentStepIndex];
  //   const stepId = currentStep.id;
  //   const optimizedEditorContent = await optimizeEditorContent(
  //     currentStep?.editor_content
  //   );
  //   const postData = {
  //     editor_content: optimizedEditorContent ? optimizedEditorContent : null,
  //     savedTime: savedTime == 0 ? 0 : savedTime,
  //     negative_time:
  //       negativeTimes[activeStepIndex] != 0
  //         ? negativeTimes[activeStepIndex]
  //         : 0,
  //     totalstepnotes: null,
  //     totaldecision: decision.join(" "),
  //     note: null,
  //     decision: decision[currentStepIndex],
  //     actions: tableData ? tableData : [],
  //     meeting_id: id,
  //     status: "active",
  //   };
  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const response = await axios.post(
  //       `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
  //       postData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: ` Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       getMeeting();
  //       setTableData(response.data.data?.planDActions); // Set the new actions for the current step
  //       // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     // toast.error(error.response?.data?.message);
  //   }
  //   setCurrentStepIndex((prevIndex) => prevIndex + 1);
  //   setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
  //   setButtonDisabled(false);
  //   setNextActiveStep();
  // };
  // const addAudioElement = async (blob) => {
  //   setButtonDisabled(true);
  //   const currentStep = inputData.steps[currentStepIndex];
  //   const stepId = currentStep.id;
  //   const formData = new FormData();
  //   const file = new File([blob], "user_audio.webm", {
  //     type: "audio/webm",
  //   });
  //   // console.log("file", file);
  //   const userId = sessionStorage.getItem("user_id");
  //   formData.append("audio_file", file);
  //   formData.append("step_id", stepId);
  //   formData.append("meeting_id", id);
  //   formData.append("user_id", userId);
  //   axios
  //     .post(`${API_BASE_URL}/audio-route`, formData)
  //     .then(async (response) => {
  //       // recorderControls.stopRecording();
  //       if (recorderControls) {
  //         recorderControls.stopRecording();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  //   await handleNext();
  //   // QueueFunction();

  // };

  // const [isLastStep, setIsLastStep] = useState(false);
  // const prev = async () => {
  //   setIsLastStep(true);
  //   recorderControls.stopRecording();
  // };

  // const saveEndData = async () => {
  //   setIsLoading(true);
  //   handlePlayPause(currentStepIndex, false);
  //   setButtonDisabled(true);
  //   // setShowNextCounter(false);
  //   const currentStep = inputData.steps[currentStepIndex];
  //   const stepId = currentStep.id;
  //   const postData = {
  //     savedTime: savedTime == 0 ? 0 : savedTime,
  //     negative_time:
  //       negativeTimes[activeStepIndex] != 0
  //         ? negativeTimes[activeStepIndex]
  //         : 0,
  //     totalstepnotes: null,
  //     note: null,
  //     decision: decision[currentStepIndex],
  //     actions: tableData ? tableData : [],
  //     meeting_id: id,
  //     status: "active",
  //   };

  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const response = await axios.post(
  //       `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
  //       postData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: ` Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
  //       return response?.data?.data?.note;
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     toast.error(error?.response?.data?.message);
  //   }
  // };

  // const lastStepData = async (blob) => {
  //   setButtonDisabled(true);
  //   const currentStep = inputData.steps[currentStepIndex];
  //   const stepId = currentStep.id;
  //   const formData = new FormData();
  //   const file = new File([blob], "user_audio.webm", {
  //     type: "audio/webm",
  //   });
  //   const userId = sessionStorage.getItem("user_id");

  //   formData.append("audio_file", file);
  //   formData.append("step_id", stepId);
  //   formData.append("meeting_id", id);
  //   formData.append("user_id", userId);
  //   axios
  //     .post(`${API_BASE_URL}/audio-route`, formData)
  //     .then(async (response) => {
  //       recorderControls.stopRecording();
  //     });
  //   const responseNotes = await saveEndData();
  //   setIsLoading(true);

  //   const updatedStepData = [...stepData];
  //   updatedStepData[updatedStepData.length - 1].note =
  //     responseNotes?.data?.data?.note;
  //   setIsLoading(true);
  //   setButtonDisabled(true);
  //   localStorage.setItem("lastURL", "/play");
  //   const updatedDatWithClosingTime = {
  //     ...meetingData,
  //     real_end_time: moment().format("HH:mm:ss"),
  //     real_start_time: real_start_time,
  //     _method: "put",
  //     plan_d_actions: tableData ? tableData : [],
  //     steps: updatedStepData,
  //     step_decisions: decision.filter((decision) => decision != ""),
  //   };
  //   try {
  //     const closingResponse = await axios.post(
  //       `${API_BASE_URL}/meetings/${id}`,
  //       updatedDatWithClosingTime,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  //   setIsLoading(true);
  //   const realEndTime = moment().format("HH:mm:ss");
  //   try {
  //     const postData = {
  //       real_end_time: realEndTime,
  //       status: "closed",
  //       _method: "put",
  //     };
  //     const response = await axios.post(
  //       `${API_BASE_URL}/meetings/${id}/status`,
  //       postData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     // console.log("meeting status api", response);
  //     if (response.status) {

  //       // console.log("meeting status changed successfully", response.data);
  //       setButtonDisabled(false);
  //       setIsLoading(false);
  //       recorderControls.stopRecording();
  //       navigate("/meeting");

  //       // setHasStartedRecording(false);
  //       // setIsAutomatic(false);
  //       // recorderControls.stopRecording();
  //     }
  //   } catch (error) {
  //     console.log("error ", error);
  //     setIsLoading(false);
  //   }
  // };

  const myLanguage = language === "en" ? "en-US" : "fr-FR";

  const chosenLanguage = language === "en" ? "English" : "French";
  console.log("chosen language", chosenLanguage);
  useEffect(() => {
    if (isAutomatic) {
      SpeechRecognition.startListening({
        continuous: true,
        language: myLanguage,
        interimResults: true,
      });
    }
  }, [isAutomatic, myLanguage]);
  // console.log('language',myLanguage)
  const [summarizedNotes, setSummarizedNotes] = useState("");

  const participants = meetingData?.participants
    ?.filter((item) => item.isCreator !== 1)
    ?.map((item) => `${item.first_name} ${item.last_name} as ${item.post}`);

  console.log("participants", participants);

  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const summarize = async () => {
    // setIsLoading(true);
    if (transcript === "") {
      return;
      setIsLoading(false);
    }
    try {
      const max = 500;
      const type = meetingData?.type;
      const context = meetingData?.description;
      const first_name = meetingData?.user?.name;
      const last_name = meetingData?.user?.last_name;
      const role = meetingData?.user?.post;
      const currentStepTitle = meetingData?.steps[currentStepIndex]?.title;

      console.log("type", type);
      const payload = {
        // model: "gpt-3.5-turbo",
        model: "gpt-4o",
        messages: [
          {
            role: "system",
            content: `Here is the current context: ${context}
            The organizer is: ${first_name} ${last_name} as ${role}
            Here is the list of guests with their roles:
            ${participants?.map((item) => item)}}
            The title of the step is ${currentStepTitle}
            Give me a summary of this step respecting the ${type} format in ${max} words maximum
            like it was a movie synopsis

            - recalling (the heading should be in h6 html tag)
            - the problem to be resolved (the heading should be in h6 html tag)
            - Addressed points (the heading should be in h6 html tag)
            - what is being done to resolve the problem (the heading should be in h6 html tag)

            The entire summary and headings must be in ${chosenLanguage}
            `,
            //   content: `You will be provided with notes. Your task is to summarize the content in ${max_words} words. If the notes are in French, provide the summary in French. If the notes are in English, provide the summary in English. Include the following details:
            // - Overall summary of the discussion (the heading should be in h6 html tag)
            // - Action items (what needs to be done and who is doing it) also (the heading should be in h6 html tag)
            // - Topics that need to be discussed more fully in the next meeting, if applicable. also (the heading should be in h6 html tag and in  heading dont include 'if applicable')`,
          },
          {
            role: "user",
            content: transcript,
          },
        ],
        temperature: 0.7,
        max_tokens: 2000,
        top_p: 1,
      };

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer sk-proj-mW72jQDqBS22ud6cOasQT3BlbkFJfxePEayqDAzTeLc4YxKk`,
          },
        }
      );
      if (response) {
        setIsLoading(false);
        let content = response?.data?.choices[0]?.message?.content;
        // Normalize the format if needed
        if (content.startsWith("```html")) {
          content = content.slice(7, -3); // Remove ```html and ```
        } else if (content.startsWith("##")) {
          content = content.replace(/^##\s*/gm, ""); // Remove leading ##
        }
        setSummarizedNotes(content);
        return content;
      }
    } catch (error) {
      console.log("error while summarizing transcipt", error);
      setIsLoading(false);
    }
  };
  const next = async () => {
    resetTranscript();
    setCurrentStepIndex((prevIndex) => prevIndex + 1);
    setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
    setButtonDisabled(false);
    setNextActiveStep();
    const summarizedNotes = await summarize();
    // setIsLoading(true);
    handlePlayPause(currentStepIndex, false);
    // setShowNextCounter(false);
    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );
    const postData = {
      editor_content: optimizedEditorContent ? optimizedEditorContent : null,
      savedTime: savedTime == 0 ? 0 : savedTime,
      negative_time:
        negativeTimes[activeStepIndex] != 0
          ? negativeTimes[activeStepIndex]
          : 0,
      totalstepnotes: summarizedNotes || "",
      totaldecision: decision.join(" "),
      note: summarizedNotes || "",
      decision: decision[currentStepIndex],
      actions: tableData ? tableData : [],
      url: inputData.steps[currentStepIndex].url
        ? inputData.steps[currentStepIndex].url
        : null,
      meeting_id: id,
      status: "active",
    };
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        // resetTranscript();
        getMeeting();
        setTableData(response.data.data?.planDActions); // Set the new actions for the current step
        // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
        setIsLoading(false);
      }
    } catch (error) {
      // toast.error(error.response?.data?.message);
    }
    // setCurrentStepIndex((prevIndex) => prevIndex + 1);
    // setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
    // setButtonDisabled(false);
    // setNextActiveStep();
  };

  // useEffect(() => {
  //   let timer;
  //   if (showProgressBar) {
  //     timer = setInterval(() => {
  //       setProgress(prevProgress => (prevProgress >= 90 ? 90 : prevProgress + 1));
  //     }, 100);
  //   }
  //   return () => clearInterval(timer);
  // }, [showProgressBar]);
  useEffect(() => {
    let timer;
    if (showProgressBar) {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 1
        );
      }, 100);
    }
    return () => clearInterval(timer);
  }, [showProgressBar]);

  const saveEndData = async () => {
    setIsLoading(true);
    const summarizedNotes = await summarize();
    setIsLoading(true);
    handlePlayPause(currentStepIndex, false);
    setButtonDisabled(true);
    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;
    const postData = {
      savedTime: savedTime == 0 ? 0 : savedTime,
      negative_time:
        negativeTimes[activeStepIndex] != 0
          ? negativeTimes[activeStepIndex]
          : 0,
      totalstepnotes: summarizedNotes || "",
      note: summarizedNotes || "",
      decision: decision[currentStepIndex],
      actions: tableData ? tableData : [],
      meeting_id: id,
      url: inputData.steps[currentStepIndex].url
        ? inputData.steps[currentStepIndex].url
        : null,

      status: "active",
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${token}`,
          },
        }
      );
      if (response.status) {
        // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
        return response?.data?.data?.note;
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    }
  };

  // const close = async () => {
  //   setButtonDisabled(true);
  //   setIsLoading(true);
  //   setShowProgressBar(true); // Show progress bar
  //   setProgress(0); // Reset progress

  //   SpeechRecognition.stopListening();
  //   localStorage.setItem("lastURL", "/play");

  //   const [hour, minute] = meetingData?.start_time?.split(":").map(Number);
  //   let endHour = hour + 1;
  //   if (endHour >= 24) {
  //     endHour -= 24;
  //   }
  //   const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;

  //   const realEndTime = moment().format("HH:mm:ss");
  //   const updatedDatWithClosingTime = {
  //     ...meetingData,
  //     real_end_time: realEndTime,
  //     real_start_time: real_start_time,
  //     _method: "put",
  //     plan_d_actions: tableData ? tableData : [],
  //     steps: stepData,
  //     step_decisions: decision.filter((decision) => decision != ""),
  //     end_time: endTimeStr,
  //   };

  //   try {
  //     // setProgress(40); // Update progress
  //     const [responseNotes, closingResponse, statusResponse] = await Promise.all([
  //       saveEndData(),
  //       axios.post(`${API_BASE_URL}/meetings/${id}`, updatedDatWithClosingTime, {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }),
  //       axios.post(
  //         `${API_BASE_URL}/meetings/${id}/status`,
  //         {
  //           real_end_time: realEndTime,
  //           status: "closed",
  //           _method: "put",
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //           },
  //         }
  //       ),
  //     ]);

  //     if (responseNotes) {
  //       const updatedStepData = [...stepData];
  //       updatedStepData[updatedStepData.length - 1].note = responseNotes;
  //       setStepData(updatedStepData);
  //     }

  //     if (closingResponse.status && statusResponse.status) {
  //       setProgress(100); // Complete progress
  //       setTimeout(() => {
  //         setShowProgressBar(false); // Hide after completion
  //         resetTranscript();
  //         setButtonDisabled(false);
  //         setIsLoading(false);
  //         navigate("/meeting");
  //       }, 500);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     setIsLoading(false);
  //     setShowProgressBar(false); // Hide progress bar on error
  //   }
  // };

  //auto notes functionality
  const close = async () => {
    setButtonDisabled(true);
    setIsLoading(true);
    setShowProgressBar(true); // Show progress bar
    setProgress(0); // Reset progress
    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;
    const responseNotes = await saveEndData();
    setIsLoading(true);
    SpeechRecognition.stopListening();
    const updatedStepData = [...stepData];
    updatedStepData[updatedStepData.length - 1].note =
      responseNotes?.data?.data?.note;
    // setIsLoading(true);
    setButtonDisabled(true);
    localStorage.setItem("lastURL", "/play");

    // Parse the start time string
    const [hour, minute] = meetingData?.start_time?.split(":").map(Number);

    // Add one hour to the hour component
    let endHour = hour + 1;

    // If end hour is greater than or equal to 24, subtract 24
    if (endHour >= 24) {
      endHour -= 24;
    }

    // Format the end time as a string
    const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;

    console.log("endTime str->", endTimeStr);
    const updatedDatWithClosingTime = {
      ...meetingData,
      real_end_time: moment().format("HH:mm:ss"),
      real_start_time: real_start_time,
      _method: "put",
      timezone: userTimeZone,
      plan_d_actions: tableData ? tableData : [],
      steps: updatedStepData,
      step_decisions: decision.filter((decision) => decision != ""),
      end_time: endTimeStr,
    };
    try {
      const closingResponse = await axios.post(
        `${API_BASE_URL}/meetings/${id}`,
        updatedDatWithClosingTime,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(true);
    const realEndTime = moment().format("HH:mm:ss");
    try {
      const postData = {
        real_end_time: realEndTime,
        status: "closed",
        _method: "put",
      };
      const response = await axios.post(
        `${API_BASE_URL}/meetings/${id}/status`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        setProgress(100);
        // SpeechRecognition.stopListening();
        // resetTranscript();
        // setButtonDisabled(false);
        // setIsLoading(false);
        // navigate("/meeting");
        setTimeout(() => {
          setShowProgressBar(false); // Hide after completion
          resetTranscript();
          setButtonDisabled(false);
          setIsLoading(false);
          navigate("/meeting");
        }, 500);
      }
    } catch (error) {
      console.log("error ", error);
      setIsLoading(false);
      setShowProgressBar(false); // Hide progress bar on error
    }
  };

  // const next = async () => {
  //   resetTranscript();
  //   // setCurrentStepIndex((prevIndex) => prevIndex + 1);
  //   // setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
  //   // setButtonDisabled(false);
  //   // setNextActiveStep();
  //   // const summarizedNotes = await summarize();
  //   // setIsLoading(true);
  //   handlePlayPause(currentStepIndex, false);
  //   // setShowNextCounter(false);
  //   const currentStep = inputData.steps[currentStepIndex];
  //   const stepId = currentStep.id;
  //   const optimizedEditorContent = await optimizeEditorContent(
  //     currentStep?.editor_content
  //   );
  //   const postData = {
  //     editor_content: optimizedEditorContent ? optimizedEditorContent : null,
  //     savedTime: savedTime == 0 ? 0 : savedTime,
  //     negative_time:
  //       negativeTimes[activeStepIndex] != 0
  //         ? negativeTimes[activeStepIndex]
  //         : 0,
  //     totalstepnotes: transcript || "",
  //     totaldecision: decision.join(" "),
  //     note: transcript || "",
  //     decision: decision[currentStepIndex],
  //     actions: tableData ? tableData : [],
  //     url: inputData.steps[currentStepIndex].url
  //       ? inputData.steps[currentStepIndex].url
  //       : null,
  //     meeting_id: id,
  //     chosenLanguage:chosenLanguage,
  //     // step_id:stepId,
  //     status: "active",
  //   };
  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const response = await axios.post(
  //       `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
  //       postData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: ` Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       // resetTranscript();
  //       getMeeting();
  //       setTableData(response.data.data?.planDActions); // Set the new actions for the current step
  //       // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     // toast.error(error.response?.data?.message);
  //   }
  //   setCurrentStepIndex((prevIndex) => prevIndex + 1);
  //   setShow((prev) => ({ ...prev, showEditor: false })); // Close the showEditor
  //   setButtonDisabled(false);
  //   setNextActiveStep();
  // };

  // const saveEndData = async () => {
  //   // setIsLoading(true);
  //   // const summarizedNotes = await summarize();
  //   setIsLoading(true);
  //   handlePlayPause(currentStepIndex, false);
  //   setButtonDisabled(true);
  //   const currentStep = inputData.steps[currentStepIndex];
  //   const stepId = currentStep.id;
  //   const postData = {
  //     savedTime: savedTime == 0 ? 0 : savedTime,
  //     negative_time:
  //       negativeTimes[activeStepIndex] != 0
  //         ? negativeTimes[activeStepIndex]
  //         : 0,
  //     totalstepnotes: transcript || "",
  //     note: transcript || "",
  //     decision: decision[currentStepIndex],
  //     actions: tableData ? tableData : [],
  //     meeting_id: id,
  //     url: inputData.steps[currentStepIndex].url
  //       ? inputData.steps[currentStepIndex].url
  //       : null,
  //     notes_language:chosenLanguage,
  //     // step_id:stepId,
  //     status: "active",
  //   };

  //   try {
  //     const token = sessionStorage.getItem("token");
  //     const response = await axios.post(
  //       `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
  //       postData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: ` Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       // setMyAllStepNote((prevNotes) => [...prevNotes, postData.note]);
  //       return response?.data?.data?.note;
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     toast.error(error?.response?.data?.message);
  //   }
  // };

  // //auto notes functionality
  // const close = async () => {
  //   setButtonDisabled(true);
  //   setIsLoading(true);
  //   const currentStep = inputData.steps[currentStepIndex];
  //   const stepId = currentStep.id;
  //   const responseNotes = await saveEndData();
  //   setIsLoading(true);
  //   SpeechRecognition.stopListening();
  //   const updatedStepData = [...stepData];
  //   updatedStepData[updatedStepData.length - 1].note =
  //     responseNotes?.data?.data?.note;
  //   // setIsLoading(true);
  //   setButtonDisabled(true);
  //   localStorage.setItem("lastURL", "/play");

  //   // Parse the start time string
  //   const [hour, minute] = meetingData?.start_time?.split(":").map(Number);

  //   // Add one hour to the hour component
  //   let endHour = hour + 1;

  //   // If end hour is greater than or equal to 24, subtract 24
  //   if (endHour >= 24) {
  //     endHour -= 24;
  //   }

  //   // Format the end time as a string
  //   const endTimeStr = `${String(endHour).padStart(2, "0")}:${String(
  //     minute
  //   ).padStart(2, "0")}`;

  //   console.log("endTime str->", endTimeStr);
  //   const updatedDatWithClosingTime = {
  //     ...meetingData,
  //     real_end_time: moment().format("HH:mm:ss"),
  //     real_start_time: real_start_time,
  //     _method: "put",
  //     plan_d_actions: tableData ? tableData : [],
  //     steps: updatedStepData,
  //     step_decisions: decision.filter((decision) => decision != ""),
  //     end_time: endTimeStr,
  //   };
  //   try {
  //     const closingResponse = await axios.post(
  //       `${API_BASE_URL}/meetings/${id}`,
  //       updatedDatWithClosingTime,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  //   setIsLoading(true);
  //   const realEndTime = moment().format("HH:mm:ss");
  //   try {
  //     const postData = {
  //       real_end_time: realEndTime,
  //       status: "closed",
  //       _method: "put",
  //     };
  //     const response = await axios.post(
  //       `${API_BASE_URL}/meetings/${id}/status`,
  //       postData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     if (response.status) {
  //       // SpeechRecognition.stopListening();
  //       resetTranscript();
  //       setButtonDisabled(false);
  //       setIsLoading(false);
  //       navigate("/meeting");
  //     }
  //   } catch (error) {
  //     console.log("error ", error);
  //     setIsLoading(false);
  //   }
  // };

  const exitManual = async () => {
    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;
    const realEndTime = moment().format("HH:mm:ss");
    handlePlayPause(currentStepIndex, false);
    setButtonDisabled(true);
    // setShowNextCounter(false);
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );
    const endTime = new Date();
    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert currentDateTime to a string in the user's local time zone
    const localEndTime = endTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      // year: 'numeric',
      // month: '2-digit',
      // day: '2-digit',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit'
    });

    try {
      const postData = {
        // real_end_time: realEndTime,
        status: "in_progress",
        // _method: "put",
        end_time: localEndTime,

        editor_content: optimizedEditorContent ? optimizedEditorContent : null,
        savedTime: savedTime == 0 ? 0 : savedTime,
        negative_time:
          negativeTimes[activeStepIndex] != 0
            ? negativeTimes[activeStepIndex]
            : 0,
        totalstepnotes: stepNotes[currentStepIndex],
        totaldecision: decision.join(" "),
        note: stepNotes[currentStepIndex],
        decision: decision[currentStepIndex],
        actions: tableData ? tableData : [],
        url: inputData.steps[currentStepIndex].url
          ? inputData.steps[currentStepIndex].url
          : null,
        meeting_id: id,
      };
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        console.log("meeting status changed successfully", response.data);
        // toast.success(response.data?.message);
        navigate("/meeting");
      }
    } catch (error) {
      // console.log("error ", error);
    }
  };

  const exitAuto = async () => {
    const summarizedNotes = await summarize();
    const currentStep = inputData.steps[currentStepIndex];
    const stepId = currentStep.id;
    const realEndTime = moment().format("HH:mm:ss");
    handlePlayPause(currentStepIndex, false);
    setButtonDisabled(true);
    // setShowNextCounter(false);
    const optimizedEditorContent = await optimizeEditorContent(
      currentStep?.editor_content
    );
    const endTime = new Date();
    // Get the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert currentDateTime to a string in the user's local time zone
    const localEndTime = endTime.toLocaleString("en-GB", {
      timeZone: userTimeZone,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      // year: 'numeric',
      // month: '2-digit',
      // day: '2-digit',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit'
    });

    try {
      const postData = {
        // real_end_time: realEndTime,
        status: "in_progress",
        // _method: "put",
        end_time: localEndTime,
        editor_content: optimizedEditorContent ? optimizedEditorContent : null,
        savedTime: savedTime == 0 ? 0 : savedTime,
        negative_time:
          negativeTimes[activeStepIndex] != 0
            ? negativeTimes[activeStepIndex]
            : 0,
        totalstepnotes: summarizedNotes || "",
        totaldecision: decision.join(" "),
        note: summarizedNotes || "",
        decision: decision[currentStepIndex],
        actions: tableData ? tableData : [],
        url: inputData.steps[currentStepIndex].url
          ? inputData.steps[currentStepIndex].url
          : null,
        meeting_id: id,
      };
      const response = await axios.post(
        `${API_BASE_URL}/play-meetings/steps/${stepId}/step-note-and-action`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      SpeechRecognition.stopListening();

      if (response.status) {
        console.log("meeting status changed successfully", response.data);
        // toast.success(response.data?.message);
        navigate("/meeting");
      }
    } catch (error) {
      // console.log("error ", error);
    }
  };

  const [iFrameLoad, setIFrameLoad] = useState(true);

  return (
    <>
      <div className="tektime">
        {loading ? (
          <Spinner
            animation="border"
            role="status"
            className="center-spinner"
          ></Spinner>
        ) : (
          <>
            <div className={showProgressBar ? "blur-container" : ""}>
              {/* {!showProgressBar ? (
              <div className="progress-overlay">
                <div style={{ width: '50%',
                  margin:'15rem auto'}}>
                  <ProgressBar now={progress} animated />
                  <h5 className="text-center my-3">Summarize your Notes...</h5>
                </div>
              </div>
            ) : ( */}
              {/* <> */}
              <div className="container-fluid mx-auto card py-5">
                <div className="text-center mb-4">
                  <h4>
                    {inputData?.objective}&nbsp; {">"} &nbsp; {inputData?.title}
                    &nbsp;
                    {/* {" >"} &nbsp; {stepTitle1} &nbsp;{" "} */}
                    {" >"} &nbsp; {inputData?.steps[currentStepIndex]?.title}{" "}
                    &nbsp;{" "}
                  </h4>
                  {/* <p>{transcript}</p>/ */}
                </div>
                <div className="text-start mb-4">
                  {inputData?.steps[currentStepIndex]?.assigned_to_name ===
                  null ? (
                    <div>
                      {inputData?.user?.image ? (
                        <img
                          className="user-img"
                          width={50}
                          height={50}
                          src={`${Assets_URL}/${inputData?.user?.image}`}
                          alt="logo"
                        />
                      ) : (
                        <FaUserCircle size={30} />
                      )}
                      <span style={{ margin: "0px 5px 0px 12px" }}>
                        {inputData?.user?.name}
                      </span>
                      <span>{inputData?.user?.last_name}</span>
                    </div>
                  ) : (
                    <div>
                      {inputData?.steps[currentStepIndex]?.assigned_to_name ? (
                        <img
                          className="user-img"
                          width={50}
                          height={50}
                          src={`${inputData?.steps[currentStepIndex]?.assigned_to_image}`}
                          alt="logo"
                        />
                      ) : (
                        <FaUserCircle size={30} />
                      )}
                      <span className="mx-2">
                        {inputData.steps[currentStepIndex]?.assigned_to_name}
                      </span>
                    </div>
                  )}
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <MdOutlineModeEdit
                      size={18}
                      cursor="pointer"
                      // className="eye-icon"
                      color={showStepContentEditor ? "#20acd4" : "black"}
                      onClick={handleStepContentEditor}
                    />
                    {editorContent && (
                      <div
                        className={`
                  ${
                    !decisionEditor.showEditor ||
                    !notesEditor.showEditor ||
                    !planDActionEditor.showEditor
                      ? "h-80"
                      : !showStepContentEditor && "card "
                  }
                
                 
                  `}
                      >
                        <div
                          className={`
                    ${
                      !decisionEditor.showEditor ||
                      !notesEditor.showEditor ||
                      !planDActionEditor.showEditor
                        ? ""
                        : "displaycard card-body"
                    }
                   
                    `}
                          style={{
                            height:
                              showStepContentEditor === false &&
                              !decisionEditor.showEditor &&
                              !notesEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              "90vh",
                            border:
                              showStepContentEditor === false &&
                              !decisionEditor.showEditor &&
                              !notesEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              "2px solid #eee",
                            overflowY:
                              showStepContentEditor === false &&
                              !decisionEditor.showEditor &&
                              !notesEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              "auto",
                            padding:
                              showStepContentEditor === false &&
                              !decisionEditor.showEditor &&
                              !notesEditor.showEditor &&
                              !planDActionEditor.showEditor &&
                              "10px",
                          }}
                        >
                          {decisionEditor.showEditor === false &&
                            showStepContentEditor === false &&
                            notesEditor.showEditor === false &&
                            planDActionEditor.showEditor === false &&
                            show.showEditor === false &&
                            // (inputData?.steps[currentStepIndex]?.editor_type ===
                            // "File" ? (
                            //   <div>
                            //     <iframe
                            //       src={
                            //         Assets_URL +
                            //         inputData?.steps[currentStepIndex]?.file
                            //       }
                            //       width="100%"
                            //       height="630px"
                            //     />
                            //   </div>
                            // ) : (
                            //   <div
                            //     className="rendered-content"
                            //     dangerouslySetInnerHTML={{
                            //       __html:
                            //         (inputData &&
                            //           inputData?.steps &&
                            //           inputData?.steps[currentStepIndex] &&
                            //           inputData?.steps[currentStepIndex]
                            //             ?.editor_content !== null) ||
                            //         inputData?.steps[currentStepIndex]
                            //           ?.editor_content !== ""
                            //           ? inputData?.steps[currentStepIndex]
                            //               ?.editor_content
                            //           : " ",
                            //     }}
                            //   />
                            // ))}
                            (inputData?.steps[currentStepIndex]?.editor_type ===
                            "File" ? (
                              <div>
                                <iframe
                                 src={`${Assets_URL + '/' + inputData?.steps[currentStepIndex].file}#toolbar=0&view=fitH`}
                                  width="100%"
                                  height="630px"
                                />
                              </div>
                            ) : inputData?.steps[currentStepIndex]
                                ?.editor_type === "Url" ? (
                              <div className="iframe-container">
                                <iframe
                                  // ref={iframeRef}
                                  src={inputData?.steps[currentStepIndex]?.url}
                                  width="100%"
                                  height="630px"
                                  onLoad={() => setIFrameLoad(false)}
                                  // onLoadStart={()=>setIFrameLoad(true)}
                                  // className={iFrameLoad ? 'iframe-hidden' : ''}
                                />
                                {iFrameLoad && <div className="loader"></div>}
                              </div>
                            ) : (
                              <div
                                className="rendered-content"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    (inputData &&
                                      inputData?.steps &&
                                      inputData?.steps[currentStepIndex] &&
                                      inputData?.steps[currentStepIndex]
                                        ?.editor_content !== null) ||
                                    inputData?.steps[currentStepIndex]
                                      ?.editor_content !== ""
                                      ? inputData?.steps[currentStepIndex]
                                          ?.editor_content
                                      : " ",
                                }}
                              />
                            ))}

                          {/* NOTES EDITOR */}
                          {notesEditor.showEditor && (
                            <>
                              <Editor
                                onBlur={(value) => {
                                  console.log("value", value);
                                }}
                                key={activeStepIndex}
                                apiKey={TINYMCEAPI}
                                value={stepNotes[activeStepIndex]}
                                init={{
                                  statusbar: false,
                                  branding: false,
                                  height: 600,
                                  menubar: true,
                                  language: "fr_FR",
                                  // language: "en_EN",
                                  plugins:
                                    "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                  toolbar:
                                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                  image_advtab: true,
                                  file_picker_types: "image",

                                  file_picker_callback: function (
                                    callback,
                                    value,
                                    meta
                                  ) {
                                    if (meta.filetype === "image") {
                                      const input =
                                        document.createElement("input");
                                      input.setAttribute("type", "file");
                                      input.setAttribute("accept", "image/*");

                                      input.onchange = function () {
                                        const file = input.files[0];
                                        const reader = new FileReader();

                                        reader.onload = function (e) {
                                          const img = new Image();
                                          img.src = e.target.result;

                                          img.onload = function () {
                                            const canvas =
                                              document.createElement("canvas");
                                            const ctx = canvas.getContext("2d");
                                            const maxWidth = 700;
                                            const maxHeight = 394;

                                            let newWidth = img.width;
                                            let newHeight = img.height;

                                            if (img.width > maxWidth) {
                                              newWidth = maxWidth;
                                              newHeight =
                                                (img.height * maxWidth) /
                                                img.width;
                                            }

                                            if (newHeight > maxHeight) {
                                              newHeight = maxHeight;
                                              newWidth =
                                                (img.width * maxHeight) /
                                                img.height;
                                            }

                                            canvas.width = newWidth;
                                            canvas.height = newHeight;

                                            ctx.drawImage(
                                              img,
                                              0,
                                              0,
                                              newWidth,
                                              newHeight
                                            );

                                            const resizedImageData =
                                              canvas.toDataURL(file.type);

                                            // Pass the resized image data to the callback function
                                            callback(resizedImageData, {
                                              alt: file.name,
                                            });
                                          };

                                          img.src = e.target.result;
                                        };

                                        reader.readAsDataURL(file);
                                      };

                                      input.click();
                                    }
                                  },
                                }}
                                // onEditorChange={(value) => {
                                //   setNotes(value);
                                // }}
                                onEditorChange={(value) => {
                                  setStepNotes((prev) => {
                                    let newStepNotes = [...prev];
                                    newStepNotes[activeStepIndex] = value;
                                    return newStepNotes;
                                  });
                                }}
                              />
                            </>
                          )}
                          {show.showEditor && (
                            <Modal
                              centered
                              size="lg"
                              show
                              onHide={() =>
                                setShow((prev) => ({
                                  ...prev,
                                  showEditor: false,
                                }))
                              }
                            >
                              <Modal.Header closeButton></Modal.Header>

                              {previousSteps?.map((item, index) => {
                                if (item.note !== null) {
                                  return (
                                    <div className="p-3" key={index}>
                                      <div className="d-flex justify-content-between">
                                        <span style={{ cursor: "pointer" }}>
                                          {item.title}
                                        </span>
                                        <MdOutlineModeEdit
                                          className="eye-icon"
                                          color={
                                            show.showEditor
                                              ? "black"
                                              : "#20acd4"
                                          }
                                          size={18}
                                          style={{ margin: "2px" }}
                                          onClick={() =>
                                            handleStepEditor(item.id)
                                          }
                                        />
                                      </div>
                                      <div
                                        className="h-100"
                                        style={{
                                          overflowY: "auto",
                                          padding: "10px",
                                          borderRadius: "5px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.note,
                                        }}
                                      />
                                    </div>
                                  );
                                }
                                return null; // If the note is null, don't render this step
                              })}
                            </Modal>
                          )}

                          {stepNoteEditor.showEditor && (
                            <Modal
                              centered
                              size="lg"
                              show
                              onHide={() =>
                                setStepEditor((prev) => ({
                                  ...prev,
                                  showEditor: false,
                                }))
                              }
                            >
                              <Modal.Header closeButton></Modal.Header>

                              <div className="d-flex justify-content-center flex-column">
                                <Editor
                                  onBlur={(value) => {
                                    console.log("value", value);
                                  }}
                                  key={activeStepIndex}
                                  apiKey={TINYMCEAPI}
                                  value={stepNote}
                                  init={{
                                    statusbar: false,
                                    branding: false,
                                    height: 500,
                                    menubar: true,
                                    language: "fr_FR",
                                    // language: "en_EN",
                                    plugins:
                                      "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                    toolbar:
                                      "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                    image_advtab: true,
                                    file_picker_types: "image",

                                    file_picker_callback: function (
                                      callback,
                                      value,
                                      meta
                                    ) {
                                      if (meta.filetype === "image") {
                                        const input =
                                          document.createElement("input");
                                        input.setAttribute("type", "file");
                                        input.setAttribute("accept", "image/*");

                                        input.onchange = function () {
                                          const file = input.files[0];
                                          const reader = new FileReader();

                                          reader.onload = function (e) {
                                            const img = new Image();
                                            img.src = e.target.result;

                                            img.onload = function () {
                                              const canvas =
                                                document.createElement(
                                                  "canvas"
                                                );
                                              const ctx =
                                                canvas.getContext("2d");
                                              const maxWidth = 700;
                                              const maxHeight = 394;

                                              let newWidth = img.width;
                                              let newHeight = img.height;

                                              if (img.width > maxWidth) {
                                                newWidth = maxWidth;
                                                newHeight =
                                                  (img.height * maxWidth) /
                                                  img.width;
                                              }

                                              if (newHeight > maxHeight) {
                                                newHeight = maxHeight;
                                                newWidth =
                                                  (img.width * maxHeight) /
                                                  img.height;
                                              }

                                              canvas.width = newWidth;
                                              canvas.height = newHeight;

                                              ctx.drawImage(
                                                img,
                                                0,
                                                0,
                                                newWidth,
                                                newHeight
                                              );

                                              const resizedImageData =
                                                canvas.toDataURL(file.type);

                                              // Pass the resized image data to the callback function
                                              callback(resizedImageData, {
                                                alt: file.name,
                                              });
                                            };

                                            img.src = e.target.result;
                                          };

                                          reader.readAsDataURL(file);
                                        };

                                        input.click();
                                      }
                                    },
                                    // images_upload_handler:image_upload_handler_callback,
                                  }}
                                  onEditorChange={(value) => {
                                    setStepNote(value);
                                  }}

                                  // onEditorChange={(value) => {
                                  //   setStepNotes((prev) => {
                                  //     let newStepNotes = [...prev];
                                  //     newStepNotes[activeStepIndex] = value;
                                  //     return newStepNotes;
                                  //   });
                                  // }}
                                />
                                <div className="d-flex justify-content-center p-2">
                                  {isUpdated ? (
                                    <>
                                      <Button
                                        variant="blue"
                                        disabled
                                        style={{
                                          backgroundColor: "#3aa5ed",
                                          border: "none",
                                          width: "18%",
                                        }}
                                      >
                                        <Spinner
                                          as="span"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          animation="border"
                                        />
                                      </Button>
                                    </>
                                  ) : (
                                    <button
                                      style={{
                                        color: "white",
                                        padding: "6px 16px",
                                        fontWeight: 500,
                                        border: "2px solid transparent",
                                        borderRadius: "10px",
                                        background: "#0d6efd",
                                      }}
                                      onClick={updateStepNote}
                                    >
                                      upddate step
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Modal>
                          )}

                          {/* DECISION EDITOR */}
                          {decisionEditor.showEditor && (
                            <>
                              <Editor
                                onBlur={(value) => {
                                  console.log("value", value);
                                }}
                                key={activeStepIndex}
                                apiKey={TINYMCEAPI}
                                value={decision[activeStepIndex]}
                                init={{
                                  statusbar: false,
                                  branding: false,
                                  height: 600,
                                  menubar: true,
                                  language: "fr_FR",
                                  // language: "en_EN",
                                  plugins:
                                    "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                  toolbar:
                                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                  image_advtab: true,
                                  file_picker_types: "image",

                                  file_picker_callback: function (
                                    callback,
                                    value,
                                    meta
                                  ) {
                                    if (meta.filetype === "image") {
                                      const input =
                                        document.createElement("input");
                                      input.setAttribute("type", "file");
                                      input.setAttribute("accept", "image/*");

                                      input.onchange = function () {
                                        const file = input.files[0];
                                        const reader = new FileReader();

                                        reader.onload = function (e) {
                                          const img = new Image();
                                          img.src = e.target.result;

                                          img.onload = function () {
                                            const canvas =
                                              document.createElement("canvas");
                                            const ctx = canvas.getContext("2d");
                                            const maxWidth = 700;
                                            const maxHeight = 394;

                                            let newWidth = img.width;
                                            let newHeight = img.height;

                                            if (img.width > maxWidth) {
                                              newWidth = maxWidth;
                                              newHeight =
                                                (img.height * maxWidth) /
                                                img.width;
                                            }

                                            if (newHeight > maxHeight) {
                                              newHeight = maxHeight;
                                              newWidth =
                                                (img.width * maxHeight) /
                                                img.height;
                                            }

                                            canvas.width = newWidth;
                                            canvas.height = newHeight;

                                            ctx.drawImage(
                                              img,
                                              0,
                                              0,
                                              newWidth,
                                              newHeight
                                            );

                                            const resizedImageData =
                                              canvas.toDataURL(file.type);

                                            // Pass the resized image data to the callback function
                                            callback(resizedImageData, {
                                              alt: file.name,
                                            });
                                          };

                                          img.src = e.target.result;
                                        };

                                        reader.readAsDataURL(file);
                                      };

                                      input.click();
                                    }
                                  },
                                }}
                                onEditorChange={(value) => {
                                  setDecision((prev) => {
                                    let newDecision = [...prev];
                                    newDecision[activeStepIndex] = value;
                                    return newDecision;
                                  });
                                }}
                              />
                            </>
                          )}

                          {!notesEditor.showEditor &&
                            !decisionEditor.showEditor &&
                            !showStepContentEditor &&
                            planDActionEditor.showEditor && (
                              <section
                                className="row py-1"
                                style={{ height: "600px" }}
                              >
                                <div className="col-md-12 mb-2">
                                  {/* Plan of Action */}
                                  <div
                                    className="card card2 p-3 table-container"
                                    style={{ height: "600px" }}
                                  >
                                    <div
                                      className="cardbody resume"
                                      style={{
                                        overflowX: "hidden",
                                        height: "auto",
                                        // minHeight: "80vh",
                                      }}
                                    >
                                      <div className=" row subtitle  text-body-secondary">
                                        <div className="col-md-2">
                                          <span>{t("presentation.order")}</span>
                                        </div>
                                        <div className=" col-md-3 ">
                                          <span>
                                            {t("presentation.action")}
                                          </span>
                                        </div>
                                        <div className="col-md-3">
                                          <span>
                                            {t("presentation.carrier")}
                                          </span>
                                        </div>
                                        <div className="col-md-3">
                                          <span>
                                            {t("presentation.dueDate")}
                                          </span>
                                        </div>
                                        <div className="col-md-1">
                                          <span></span>
                                        </div>
                                      </div>

                                      {tableData?.map((rowData, index) => {
                                        if (
                                          rowData.step_id !==
                                          inputData.steps[currentStepIndex].id
                                        ) {
                                          return;
                                        }
                                        return (
                                          <div
                                            className="row p-2 text-body-dark mt-3 "
                                            style={{
                                              borderBottom: "1px solid #ccc",
                                            }}
                                            key={index}
                                          >
                                            <div className="col-md-2">
                                              <select
                                                className="form-select form-select-sm"
                                                value={rowData.order}
                                                onChange={(e) => {
                                                  handleTableDataChange(
                                                    e,
                                                    index
                                                  );
                                                }}
                                                name="order"
                                              >
                                                {Array.from({
                                                  length: 11,
                                                }).map((_, i) => (
                                                  <option key={i} value={i}>
                                                    {i}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>

                                            <div className="col-md-3">
                                              <textarea
                                                name="action"
                                                value={rowData.action}
                                                onChange={(e) => {
                                                  handleTableDataChange(
                                                    e,
                                                    index
                                                  );
                                                }}
                                                placeholder="Action"
                                                rows={3}
                                                // maxLength={100}
                                                className="wrapped-textarea txt"
                                              />
                                            </div>

                                            <div className="col-md-3">
                                              <select
                                                className="form-select form-select-sm"
                                                // value={rowData.participant_id || ""}
                                                value={rowData.participant_id}
                                                name="participant_id"
                                                onChange={(e) =>
                                                  handleTableDataChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              >
                                                <option value="" disabled>
                                                  {t("Select Participant")}
                                                </option>
                                                {/* {inputData.participants &&
                                            inputData?.participants?.length >
                                              0 && (
                                              <option value="all">
                                                All Participants
                                              </option>
                                            )} */}
                                                {inputData.participants &&
                                                  inputData.participants
                                                    ?.length < 1 && (
                                                    <option value="">
                                                      No Participants Added{" "}
                                                    </option>
                                                  )}
                                                {inputData.participants &&
                                                  inputData.participants
                                                    .filter(
                                                      (
                                                        participant,
                                                        index,
                                                        self
                                                      ) =>
                                                        index ===
                                                        self.findIndex(
                                                          (p) =>
                                                            p.email ===
                                                            participant.email
                                                        )
                                                    )
                                                    ?.map((item) => {
                                                      return (
                                                        <option
                                                          key={item.id}
                                                          value={item.id}
                                                        >
                                                          {item.first_name}{" "}
                                                          {item.last_name}
                                                        </option>
                                                      );
                                                    })}
                                              </select>
                                            </div>

                                            <div className="col-md-3">
                                              <div>
                                                <img
                                                  src="/Assets/minus1.svg"
                                                  alt="minus"
                                                  className="img-fluid "
                                                  width={"15px"}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleDecrementCount(index)
                                                  }
                                                />{" "}
                                                &nbsp; &nbsp;
                                                <span>
                                                  {parseInt(
                                                    rowData.action_days
                                                  )}{" "}
                                                  {t("Day")}
                                                </span>
                                                &nbsp;&nbsp;
                                                <img
                                                  src="/Assets/plus1.svg"
                                                  alt="plus"
                                                  className="img-fluid"
                                                  width={"15px"}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleIncrementCount(index)
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-1">
                                              <button
                                                className="btndel"
                                                onClick={() =>
                                                  handleButtonDelete(index)
                                                }
                                              >
                                                <AiFillDelete
                                                  size={"25px"}
                                                  color="red"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      })}

                                      <div className="d-flex justify-content-center mt-3 gap-2">
                                        <div>
                                          <GoPlusCircle
                                            size="30px"
                                            onClick={handleButtonClick}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                          {
                            // STEP EDITORRRR
                            !notesEditor.showEditor &&
                            !decisionEditor.showEditor &&
                            !planDActionEditor.showEditor &&
                            showStepContentEditor ? (
                              <div>
                                {/* FILE UPLOADDD */}
                                <section>
                                  <ShowIF
                                    condition={
                                      inputData.steps[currentStepIndex]
                                        .editor_type === "File"
                                    }
                                  >
                                    <label>
                                      {inputData.steps[currentStepIndex].file}
                                    </label>
                                    <input
                                      type="file"
                                      multiple="false"
                                      // value={inputData.steps[currentStepIndex].file}
                                      onChange={async (e) => {
                                        console.log("file", e.target.files[0]);
                                        const file = e.target.files[0];
                                        const allowedFileTypes = [
                                          "application/pdf",
                                          "application/vnd.ms-excel",
                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                          "application/vnd.ms-powerpoint",
                                          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                          "application/msword",
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                        ];
                                        if (
                                          !file ||
                                          !allowedFileTypes.includes(file.type)
                                        ) {
                                          alert(
                                            "Please select a valid file type"
                                          );
                                        }
                                        // PREPARE THE PAYLOAD
                                        const updatedSteps = [
                                          ...(inputData?.steps || []),
                                        ];
                                        const selectedStep =
                                          updatedSteps[currentStepIndex];
                                        const filePayload = {
                                          title: selectedStep.title,
                                          count1: selectedStep.count1,
                                          count2: selectedStep.count2,
                                          time: selectedStep.count2,
                                          editor_type: selectedStep.editor_type,
                                          file: file,
                                          editor_content: null,
                                          _method: "put",
                                        };
                                        // SEND THE FILE TO THE SERVER
                                        try {
                                          const response = await axios.post(
                                            `${API_BASE_URL}/steps/${selectedStep?.id}`,
                                            filePayload,
                                            {
                                              headers: {
                                                "Content-Type":
                                                  "multipart/form-data",
                                                Authorization: `Bearer ${sessionStorage.getItem(
                                                  "token"
                                                )}`,
                                              },
                                            }
                                          );
                                          // console.log(
                                          //   "response--> file uploaded",
                                          //   response
                                          // );
                                          if (response.status === 200) {
                                            // Update the file in the state
                                            const updatedSteps = [
                                              ...(inputData?.steps || []),
                                            ];
                                            const selectedStep =
                                              updatedSteps[currentStepIndex];
                                            selectedStep.file =
                                              response.data.data.file;
                                            setInputData({
                                              ...inputData,
                                              steps: updatedSteps,
                                            });
                                          }
                                        } catch (error) {
                                          console.log(
                                            "error while uploading file",
                                            error
                                          );
                                        }
                                      }}
                                    />
                                  </ShowIF>
                                </section>
                                <ShowIF
                                  condition={
                                    inputData.steps[currentStepIndex]
                                      .editor_type === "Editeur"
                                  }
                                >
                                  <Editor
                                    onBlur={(value) => {
                                      console.log("value", value);
                                    }}
                                    key={activeStepIndex}
                                    apiKey={TINYMCEAPI}
                                    value={
                                      inputData?.steps[activeStepIndex]
                                        ?.editor_content
                                    }
                                    init={{
                                      statusbar: false,
                                      branding: false,
                                      height: 900,
                                      menubar: true,
                                      language: "fr_FR",
                                      // language: "en_EN",
                                      plugins:
                                        "print preview paste searchreplace image autolink directionality visualblocks visualchars fullscreen  link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                                      toolbar:
                                        "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | image | imagePicker link media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                                      // image_advtab: true,
                                      file_picker_types: "image",
                                      images_upload_handler:
                                        image_upload_handler_callback,
                                    }}
                                    onEditorChange={(content) => {
                                      setInputData((prevData) => ({
                                        ...prevData,
                                        steps: prevData.steps.map(
                                          (step, index) => {
                                            if (index === activeStepIndex) {
                                              return {
                                                ...step,
                                                editor_content: content,
                                              };
                                            }
                                            return step;
                                          }
                                        ),
                                      }));
                                    }}
                                    onInit={(evt, editor) => {
                                      editorRef.current = editor;
                                    }}
                                    onNodeChange={(e) => {
                                      if (
                                        e &&
                                        e.element.nodeName.toLowerCase() ==
                                          "img"
                                      ) {
                                        editorRef.current.dom.setAttribs(
                                          e.element,
                                          {
                                            width: "700px",
                                            height: "394px",
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </ShowIF>
                                <ShowIF
                                  condition={
                                    inputData?.steps[currentStepIndex]
                                      .editor_type === "Url"
                                  }
                                >
                                  <label>
                                    {inputData?.steps[currentStepIndex]?.Url}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="https://www.google.com"
                                    value={
                                      inputData?.steps[currentStepIndex]?.url
                                    }
                                    // onChange={handleLinkUpload}
                                    onChange={(e) => {
                                      setInputData((prevData) => ({
                                        ...prevData,
                                        steps: prevData.steps.map(
                                          (step, index) => {
                                            if (index === activeStepIndex) {
                                              return {
                                                ...step,
                                                url: e.target.value,
                                              };
                                            }
                                            return step;
                                          }
                                        ),
                                      }));
                                    }}
                                    name="url"
                                    style={{ width: "50%" }}
                                  />
                                </ShowIF>
                              </div>
                            ) : null
                          }
                          {/* {
                        // STEP EDITORRRR
                        !notesEditor.showEditor &&
                        !decisionEditor.showEditor &&
                        !planDActionEditor.showEditor &&
                        inputData?.steps[currentStepIndex].editor_type === "Url" &&
                        showStepContentEditor ? (
                          <div>
                            <ShowIF
                              condition={
                                inputData?.steps[currentStepIndex]
                                  .editor_type === "Url"
                              }
                            >
                                <label>
                                  {inputData?.steps[currentStepIndex]?.Url}
                                </label>
                                <input
                                  type="text"
                                  placeholder="https://www.google.com"
                                  value={inputData?.steps[currentStepIndex]?.url}
                                  // onChange={handleLinkUpload}
                                  name="url"
                                  style={{width:'50%'}}
                                />
                            </ShowIF>
                          </div>
                        ) : null
                      } */}
                        </div>
                      </div>
                    )}

                    <div
                      className="d-flex justify-content-center text-center gap-2"
                      style={{ marginTop: "8rem" }}
                    >
                      <button
                        className="btn btn-danger"
                        onClick={updateMeetingStatus}
                      >
                        {t("Abort Moment")}
                      </button>
                      {isAutomatic ? (
                        <button
                          className="btn btn-danger"
                          onClick={() => exitAuto()}
                        >
                          {t("Exit Moment")}
                        </button>
                      ) : (
                        <button
                          className="btn btn-danger"
                          onClick={() => exitManual()}
                        >
                          {t("Exit Moment")}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="">
                      <CounterContainer
                        alarm={meetingData?.alarm || "0"}
                        progress={showProgressBar}
                      />
                    </div>

                    {!isAutomatic && (
                      <div>
                        {currentStepIndex !== inputData?.steps?.length - 1 ? (
                          <>
                            <div className="d-flex justify-content-center pt-3 mb-3">
                              <br />
                              <div className="d-flex gap-3 prev-btn">
                                {currentStepIndex > 0 && (
                                  <>
                                    {isPrevious ? (
                                      <>
                                        <button className={`btn btn-primary`}>
                                          <Spinner
                                            as="span"
                                            variant="light"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            animation="border"
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className="btn btn-primary"
                                        onClick={previousPage}
                                      >
                                        Précédent
                                      </button>
                                    )}
                                  </>
                                )}

                                {isNext ? (
                                  <>
                                    <button className={`btn btn-primary`}>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className={`btn ${
                                      currentStepIndex ===
                                      inputData?.steps?.length - 1
                                        ? "btn-success"
                                        : "btn-primary"
                                    }`}
                                    onClick={() =>
                                      currentStepIndex ===
                                      inputData.steps.length - 1
                                        ? closeMeeting
                                        : handlenextPage()
                                    }
                                    // disabled={isButtonDisabled}
                                  >
                                    {currentStepIndex ===
                                    inputData.steps.length - 1
                                      ? t("Close")
                                      : t("Next")}
                                  </button>
                                )}
                                {/* </>
                        )} */}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="d-flex justify-content-center pt-3 mb-3">
                            <br />
                            <div className="d-flex  gap-3 prev-btn ">
                              {currentStepIndex !== inputData?.steps?.length &&
                                currentStepIndex !== 0 && (
                                  <>
                                    {isPrevious ? (
                                      <>
                                        <button className={`btn btn-primary`}>
                                          <Spinner
                                            as="span"
                                            variant="light"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            animation="border"
                                          />
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className="btn btn-primary"
                                        onClick={async () => {
                                          await previousPage();
                                          setPreviousActiveStep();
                                        }}
                                        // onClick={backPage}
                                      >
                                        {t("Previous")}
                                      </button>
                                    )}
                                  </>
                                )}
                              <button
                                className={` btn ${
                                  currentStepIndex ===
                                  inputData?.steps?.length - 1
                                    ? "btn-success"
                                    : "btn-primary"
                                }`}
                                // onClick={
                                //   () => handlenextPage(inputData)
                                //   // disabled={isButtonDisabled}
                                // }
                                disabled={buttonDisabled}
                                onClick={() =>
                                  currentStepIndex ===
                                  inputData.steps.length - 1
                                    ? closeMeeting()
                                    : handlenextPage()
                                }
                              >
                                {currentStepIndex ===
                                inputData.steps.length - 1 ? (
                                  isLoading ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </>
                                  ) : (
                                    buttonText
                                  )
                                ) : (
                                  "suviant"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {isAutomatic && (
                      <div>
                        {currentStepIndex !== inputData?.steps?.length - 1 ? (
                          <>
                            <div className="d-flex justify-content-center pt-3 mb-3">
                              <br />
                              <div className="d-flex  gap-3 prev-btn ">
                                {isLoading ? (
                                  <>
                                    <button className={`btn btn-primary`}>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => next()}
                                    // onClick={() => recorderControls.stopRecording()}
                                  >
                                    {t("Next")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center pt-3 mb-3">
                              <br />
                              <div className="d-flex  gap-3 prev-btn ">
                                {isLoading ? (
                                  <>
                                    <button className={`btn btn-success`}>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-success"
                                    onClick={() => close()}
                                    // onClick={() => prev()}
                                  >
                                    {t("Close")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {/* ...................START................ */}
                    {isAutomatic ? (
                      <>
                        {/* -------------------WHEN NOTES AUTOMATIC------------- */}
                        {
                          <div className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <label className="form-label m-0">
                                {/* <img
                              src="/Assets/Ellipse 8.png"
                              className="img-fluid pb-1"
                              alt="circle"
                            />{" "} */}
                                &nbsp; {t("Take Notes")}
                              </label>
                            </div>
                            <div
                              style={{ height: "50px", visibility: "hidden" }}
                            >
                              {/* <AudioRecorder
                            onRecordingComplete={(blob) => {
                              if (isLastStep) {
                                lastStepData(blob);
                              } else if (isExit) {
                                ExitMeeting(blob);
                              } else {
                                addAudioElement(blob);
                                recorderControls.startRecording();
                              }
                              recorderControls.stopRecording();
                            }}
                            recorderControls={recorderControls}
                            showVisualizer={true}
                          /> */}
                              <br />
                              {/* <button onClick={recorderControls.stopRecording}>
                            Stop recording
                          </button> */}
                              <br />
                            </div>
                          </div>
                        }

                        {/* ---DECISION */}
                        <div className="">
                          <label className="form-label mb-3">
                            <MdOutlineModeEdit
                              className="eye-icon"
                              // color="#20acd4"
                              color={
                                decisionEditor.showEditor ? "#20acd4" : "black"
                              }
                              size={18}
                              style={{
                                margin: "2px",
                              }}
                              onClick={handleDecisionEditorToggle}
                            />
                            &nbsp; {t("Decision")}
                          </label>
                        </div>
                        {
                          <div style={{ height: "200px" }}>
                            <div
                              key={activeStepIndex}
                              className="h-100"
                              style={{
                                overflowY: "auto",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: decision[activeStepIndex],
                              }}
                            />
                          </div>
                        }

                        {/* --------PlanDAction */}
                        <div className="card-body p-0 mt-1">
                          <h5 className="card-title ">
                            <div className="mb-3">
                              <label className="form-label mb-3">
                                <MdOutlineModeEdit
                                  className="eye-icon"
                                  // color="#20acd4"
                                  color={
                                    planDActionEditor.showEditor
                                      ? "#20acd4"
                                      : "black"
                                  }
                                  size={18}
                                  style={{
                                    margin: "2px",
                                  }}
                                  onClick={handlePlanDActionEditor}
                                />
                                &nbsp; {t("Strategy")}
                              </label>
                            </div>
                          </h5>
                          {
                            <div
                              className="cardbody resume card2"
                              style={{ overflowX: "hidden" }}
                            >
                              {tableData?.map((rowData, index) => {
                                console.log("rowData", rowData);
                                if (
                                  rowData.step_id !=
                                  inputData.steps[currentStepIndex].id
                                ) {
                                  return;
                                }
                                return (
                                  <div
                                    className="text-body-dark p-2"
                                    style={{ borderBottom: "1px solid #ccc" }}
                                    key={index}
                                  >
                                    <div className="row">
                                      <div className="col-md-12">
                                        <textarea
                                          name="action"
                                          value={rowData?.action}
                                          onChange={(e) =>
                                            handleTableDataChange(e, index)
                                          }
                                          placeholder="Action"
                                          rows={3}
                                          // maxLength={100}
                                          className="wrapped-textarea"
                                          style={{
                                            background: "white",
                                            resize: "none",
                                            width: "100%",
                                            outline: "none",
                                            border: "none",
                                            borderRadius: "0px",
                                          }}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          }
                        </div>
                        {/* -------------------WHEN NOTES AUTOMATIC------------- */}
                      </>
                    ) : (
                      <>
                        {/* -------------------WHEN NOTES MANUAL------------- */}
                        <div className="mb-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <label className="form-label mb-3">
                              <MdOutlineModeEdit
                                className="eye-icon"
                                color={
                                  notesEditor.showEditor ? "#20acd4" : "black"
                                }
                                size={18}
                                style={{
                                  margin: "2px",
                                }}
                                onClick={handleNotesEditorToggle}
                              />
                              &nbsp; {t("Notes")}
                            </label>
                            <span
                              onClick={handleShow}
                              style={{
                                cursor: "pointer",
                                color: show.showEditor ? "#20acd4" : "black",
                                fontWeight: "bold",
                              }}
                            >
                              see all notes
                            </span>
                          </div>
                          {
                            <div
                              style={{ height: "200px" }}
                              className="cardbody resume card2"
                            >
                              <div
                                key={activeStepIndex}
                                className="h-100"
                                style={{
                                  overflowY: "auto",
                                  borderBottom: "1px solid #ccc",
                                  padding: "10px",
                                  borderRadius: "5px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: stepNotes[activeStepIndex],
                                }}
                              />
                            </div>
                          }
                        </div>
                        <div className="">
                          <label className="form-label mb-3">
                            <MdOutlineModeEdit
                              className="eye-icon"
                              color={
                                decisionEditor.showEditor ? "#20acd4" : "black"
                              }
                              size={18}
                              style={{
                                margin: "2px",
                              }}
                              onClick={handleDecisionEditorToggle}
                            />
                            &nbsp; {t("Decision")}
                          </label>
                        </div>
                        {
                          <div style={{ height: "200px" }}>
                            <div
                              key={activeStepIndex}
                              className="h-100"
                              style={{
                                overflowY: "auto",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: decision[activeStepIndex],
                              }}
                            />
                          </div>
                        }
                        <div className="card-body p-0 mt-3">
                          <h5 className="card-title ">
                            <div className="mb-3">
                              <label className="form-label mb-3">
                                <MdOutlineModeEdit
                                  className="eye-icon"
                                  // color="#20acd4"
                                  color={
                                    planDActionEditor.showEditor
                                      ? "#20acd4"
                                      : "black"
                                  }
                                  size={18}
                                  style={{
                                    margin: "2px",
                                  }}
                                  onClick={handlePlanDActionEditor}
                                />
                                &nbsp; {t("Strategy")}
                              </label>
                            </div>
                          </h5>
                          {
                            <div
                              className="cardbody resume card2"
                              style={{ overflowX: "hidden" }}
                            >
                              {tableData?.map((rowData, index) => {
                                if (
                                  rowData.step_id !=
                                  inputData.steps[currentStepIndex].id
                                ) {
                                  return;
                                }
                                return (
                                  <div
                                    className="text-body-dark p-2"
                                    style={{ borderBottom: "1px solid #ccc" }}
                                    key={index}
                                  >
                                    <div className="row">
                                      <div className="col-md-12">
                                        <textarea
                                          name="action"
                                          value={rowData.action}
                                          onChange={(e) =>
                                            handleTableDataChange(e, index)
                                          }
                                          placeholder="Action"
                                          rows={3}
                                          // maxLength={100}
                                          className="wrapped-textarea txt"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            // )
                          }
                        </div>
                        {/* -------------------WHEN NOTES MANUAL------------- */}
                      </>
                    )}
                    {/* ...................END................ */}
                  </div>
                </div>
              </div>
              {/* </> */}
              {/* // )} */}
            </div>

            {showProgressBar && (
              <div className="progress-overlay">
                <div style={{ width: "50%" }}>
                  <ProgressBar now={progress} animated />
                  <h5 className="text-center my-3">Summarize your Notes...</h5>
                </div>
              </div>
            )}
          </>
        )}

        <div>
          {GradientSvg}
          {GradientSvg2}
          {GradientSvg3}
        </div>
      </div>
    </>
  );
};

export default Play;
