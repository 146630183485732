import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

function BasePage(props) {
  const location = useLocation();
  const routesWithBasePage = [
    "/",
    "/about",
    "/privacy-policy",
    "/terms&conditions",
  ];

  const shouldShowHeaderFooter = routesWithBasePage.includes(location.pathname);

  return (
    <div>
      {shouldShowHeaderFooter && <Navbar />}
      {props.children}
      {shouldShowHeaderFooter && <Footer />}
    </div>
  );
}

export default BasePage;
