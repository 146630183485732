import React from "react";
import { Assets_URL } from "../../Apicongfig";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const StepCard = ({ data, startTime, users,meeting }) => {
  console.log('user',users)
  const [t] = useTranslation("global");
  return (
    <div className="row" style={{ marginBottom: "6rem" }}>
      {data?.map((item, index) => {
        let editorContent = item.editor_content;
        // Create a temporary div element to parse the HTML content
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = editorContent;

        // Find the first image tag
        const firstImageTag = tempDiv.querySelector("img");

        // Extract the src attribute from the first image tag
        const firstImageUrl = firstImageTag
          ? firstImageTag.getAttribute("src")
          : "";

        let stepTime = item.count2;
        console.log("stepTime: ", stepTime);
        console.log("startTime: ", startTime);

        // Parse the time correctly
        let [time, modifier] = startTime.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        // Convert to 24-hour format if necessary
        if (modifier === "PM" && hours < 12) {
          hours += 12;
        }
        if (modifier === "AM" && hours === 12) {
          hours = 0;
        }

        // Create a new Date object and set the hours and minutes
        let startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);
        startDate.setSeconds(0);

        // Add stepTime minutes to the start date
        startDate.setMinutes(startDate.getMinutes() + stepTime);

        // Format the new time in 12-hour format with AM/PM
        const formattedTimeAfterAddingStepsTime = startDate.toLocaleTimeString(
          "en-US",
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }
        );

        console.log(
          "formattedTime after adding step count2",
          formattedTimeAfterAddingStepsTime
        );
        return (
          <div className="col-md-12" key={index}>
            <Card className="mt-4 step-card">
              <Card.Body className="d-flex">
                <div className="d-flex align-items-center">
                  {item.editor_content ? (
                    // <div
                    //   style={{
                    //     background: "#eaeaef",
                    //     borderRadius: "10px",
                    //     display: "flex",
                    //     alignItems: "center",
                    //   }}
                    // >
                    //   <Card.Img
                    //     className="step-img "

                    //     src={firstImageUrl ? firstImageUrl : "/Assets/Tek.png"}
                    //   />
                    // </div>
                    <div
                      style={{
                        width: "193.14px",
                        height: "104px",
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {firstImageUrl ? (
                        <Card.Img className="step-img" src={firstImageUrl} />
                      ) : (
                        <div
                          style={{
                            background: "#eaeaef",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src="/Assets/Tek.png"
                            style={{ width: "50px", height: "50px" }}
                            alt="Fallback Image"
                          />
                        </div>
                      )}
                    </div>
                  ) : item.file ? (
                    <div
                      style={{
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Card.Img
                        className="file-img img-fluid"
                        src="/Assets/pdf-svgrepo-com.svg"
                      />
                    </div>
                  ) : item.url ? (
                    <div
                      style={{
                        width: "193.14px",
                        height: "104px",
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Card.Img
                        className="link-img"
                        style={{
                          height: "35px",
                          width: "35px",
                          filter: "opacity(0.5)",
                        }}
                        // src="/Assets/Thumbnail.png"
                        src={`/Assets/link-removebg.png`}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "193.14px",
                        height: "104px",
                        background: "#eaeaef",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Card.Img
                        className="link-img"
                        style={{
                          height: "50px",
                          width: "50px",
                        }}
                        src={`/Assets/Tek.png`}
                      />
                    </div>
                    // <div className="step-img ">
                    //   <Card.Img className="img-fluid" src={`/Assets/logo2.png`} />
                    // </div>
                  )}
                </div>

                <div className="ms-3 d-flex justify-content-center flex-column step-data">
                  <Card.Title className="step-card-heading">
                    {/* Step {index + 1}: {item?.title} */}
                    {`${t("meeting.newMeeting.labels.step")} ${index + 1}`}: {item?.title}
                  </Card.Title>
                  <Card.Subtitle className="step-card-subtext">
                    {item?.assigned_to_image ? (
                      <img
                        height="24px"
                        width="24px"
                        style={{ marginRight: "9px", borderRadius: "20px" }}
                        src={item?.image ? `${Assets_URL}/${item?.image}` : item?.assigned_to_image}
                        alt="Assigned To"
                      />
                    ) : (
                      <img
                        height="24px"
                        width="24px"
                        style={{ marginRight: "9px", borderRadius: "20px" }}
                        src={users?.image ? `${Assets_URL}/${users?.image}` : meeting?.user?.participant_image}
                        alt="User"
                      />
                    )}
                    <span>
                      {item?.assigned_to_name ||
                        `${users?.firstName} ${users?.lastName}`}
                    </span>
                  </Card.Subtitle>

                  {/* <Card.Text className="step-card-content">
                  </Card.Text> */}
                  <Card.Text className="step-card-content">
                    <img
                      height="16px"
                      width="16px"
                      style={{ width: "auto", marginRight: "9px" }}
                      src="/Assets/ion_time-outline.svg"
                    />
                    <span className="me-2">
                      {/* {index === 0
                        ? startTime
                        : formattedTimeAfterAddingStepsTime} */}
                      {/* {index === 0 ? startTime : item?.step_total_time} */}
                      {item?.total_step_time}
                    </span>
                    <img
                      height="16px"
                      width="16px"
                      style={{ width: "auto", marginRight: "9px" }}
                      src="/Assets/alarm-invite.svg"
                    />
                    <span>{item?.count2 + " " + "Mins"}</span>
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default StepCard;
