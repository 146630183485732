import React from "react";

const TermOfService = () => {
  return (
    <div
      className="container-fluid text-justify"
      style={{ padding: "0px 24px" }}
    >
      <h1>TekTIME Terms of Service</h1>

      <div className="privacy-content">
        <h2>Introduction</h2>
        <p>
          Welcome to TekTIME. By accessing and using our service, you agree to
          comply with these terms of service. Please read them carefully.
        </p>

        <div>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By creating an account and using TekTIME, you agree to be bound by
            these terms of service. If you do not accept these terms, you should
            not use our service.
          </p>
        </div>

        <div className="first">
          <h2>2. Use of Service</h2>

          <div>
            <h5>2.1. User Account</h5>
            <p>
              To use TekTIME, you must create an account by providing accurate
              and complete information. You are responsible for maintaining the
              confidentiality of your password and for all activities that occur
              under your account. You agree to notify us immediately of any
              unauthorized use of your account.
            </p>
          </div>
          <div>
            <h5>2.2. License to Use</h5>
            <p>
              Subject to your compliance with these terms, we grant you a
              limited, non-exclusive, non-transferable, and revocable license to
              access and use TekTIME solely for your internal business needs.
            </p>
          </div>
        </div>

        <h2>3. Usage Restrictions</h2>
        <p>You agree not to:</p>
        <ul>
          <li> Use TekTIME for any illegal or unauthorized purposes.</li>
          <li>
            Violate any applicable local, national, or international laws.
          </li>
          <li>Transmit any viruses or any destructive code.</li>
          <li>Attempt to bypass any security measures of TekTIME.</li>
          <li>
            Reproduce, duplicate, copy, sell, resell, or exploit any part of
            TekTIME without our express written permission.
          </li>
        </ul>

        <div className="first">
          <h2>4. User Content</h2>

          <div>
            <h5>4.1. Content Ownership</h5>
            <p>
              You retain all ownership rights to the content you create or
              upload on TekTIME. By uploading content, you grant us a worldwide,
              non-exclusive, royalty-free, transferable, and sublicensable
              license to use, reproduce, modify, adapt, publish, and display
              this content as part of providing TekTIME.
            </p>
          </div>
          <div>
            <h5>4.2. Content Responsibility</h5>
            <p>
              You are solely responsible for the content you upload or create on
              TekTIME. We reserve the right to remove or disable access to any
              content that we, in our discretion, find in violation of these
              terms or any applicable law.
            </p>
          </div>
        </div>

        <div className="first">
          <h2>5. Payment and Billing</h2>

          <div>
            <h5>5.1. Pricing</h5>
            <p>
              The use of certain features of TekTIME may be subject to fees. You
              agree to pay all applicable fees for your use of TekTIME according
              to the rates in effect at the time of your use.
            </p>
          </div>
          <div>
            <h5>5.2. Billing</h5>
            <p>
              We will issue invoices for incurred fees, and you agree to pay
              them within the specified time. In case of non-payment, we reserve
              the right to suspend or terminate your access to TekTIME.
            </p>
          </div>
        </div>

        <h2>6. Privacy</h2>
        <p>
          Your use of TekTIME is also governed by our privacy policy, which
          explains how we collect, use, and protect your personal information.
        </p>

        <h2>7. Termination</h2>
        <p>
          We reserve the right to suspend or terminate your access to TekTIME at
          any time, with or without notice, for any violation of these terms or
          for any other reason we deem appropriate. You may also terminate your
          account at any time by contacting us.
        </p>

        <h2>8. Limitation of Liability </h2>
        <p>
          To the fullest extent permitted by law, we disclaim all liability for
          any direct, indirect, incidental, consequential, or punitive damages
          arising from your use of TekTIME. Our total liability to you for any
          claim arising from the use of TekTIME will be limited to the amount
          you have paid, if any, to access TekTIME.
        </p>

        <h2>9. Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. We will notify
          you of any changes by posting the new terms on our website. Your
          continued use of TekTIME after the posting of changes constitutes your
          acceptance of the new terms.
        </p>

        <h2>10. Governing Law</h2>
        <p>
          These terms are governed by the laws of France. Any dispute arising
          from these terms or the use of TekTIME will be subject to the
          exclusive jurisdiction of the French courts.
        </p>
      </div>
    </div>
  );
};

export default TermOfService;
