import React from 'react'
import InvitiesTabs from './InvitiesTabs'
import { useHeaderTitle } from '../../../context/HeaderTitleContext';
import CurrentGoals from './CurrentGoals';

const Invities = () => {
  const {resetHeaderTitle} = useHeaderTitle();
  React.useEffect(() => {
    resetHeaderTitle();
  }, []);
  return (
    <>
    {/* <InvitiesTabs/> */}
    <CurrentGoals
              // eventKey="Nouveaux objectifs"
              // setActiveTab={handleTabChange}
            />
    </>
  )
}

export default Invities