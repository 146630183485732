import React, { useEffect, useState } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { LuClock7, LuClock9 } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import { IoMdEye } from "react-icons/io";
import { Modal, Stack } from "react-bootstrap";
import Chart from "./Chart";
import PlanDChart from "./PlanDChart";
import Select from "react-select";
import { AiOutlineUser } from "react-icons/ai";
import { getUserRoleID } from "../../Utils/getSessionstorageItems";

const Presentation = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const location = window.location.href;
  const [len, setLen] = useState();
  const [leftBtn, setLeftBtn] = useState(false);
  const { objective } = useParams();
  console.log("objective", objective);
  const [imageError, setImageError] = useState(false);
  const [t] = useTranslation("global");
  const [meetingId, setMeetingId] = useState(useParams().id);
  const { meetingId: meetId } = useParams();
  console.log("meetId", meetId);

  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState([]); // response Data.
  console.log("meetingData", meetingData);
  //FORM BINDED VALUEs:
  const [steps, setSteps] = useState([]); // steps of meeting.
  console.log("steps", steps);
  const [profileData, setProfileData] = useState({}); // profile data of user who created meeting.
  const [summaryOfNotes, setSummaryOfNotes] = useState([]); // summary of notes.
  const [planOfAction, setPlanOfAction] = useState([]); // plan of action.
  const [decisions, setDecisions] = useState([]); // decisions.
  const [notes, setNotes] = useState([]); // notes.
  const [participants, setParticipants] = useState([]); // participants.
  const [loading, setLoading] = useState(true);
  const [pic, setPic] = useState();
  const [meetingCount, setMeetingCount] = useState();
  const [userProfile, setUserProfile] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  console.log("current index: ", currentIndex);

  console.log("meetingCount: ", meetingCount);
  // For fetching the meeting data
  // useEffect(() => {
  //   const getMeetingByID = async () => {
  //     try {
  //       setLoading(true);
  //       const token = sessionStorage.getItem("token");
  //       // const REQUEST_URL = `${API_BASE_URL}/meetingView/${meetingId}`;
  //       // const REQUEST_URL =  `${API_BASE_URL}/meetingViewDestination/${id}/${objective}`;
  //       const REQUEST_URL =  `${API_BASE_URL}/get-public-meetings/${objective}`;

  //       const response = await axios.get(REQUEST_URL, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       const _data = response.data.data;
  //       console.log("data all meetings",_data)
  //       console.log("_data",_data)
  //       if (_data) {
  //         const meeting = _data?.find(meeting => meeting.id);
  //         console.log('meeting filter', meeting)
  //         setSteps(_data)
  //         setMeetingCount(_data?.destinationCount);
  //         setImage(response.data.data?.user?.image);
  //         setPic(response.data.data?.user?.enterprise.logo);
  //         setMeetingData(response.data.data);
  //         setParticipants(response.data?.data?.participants);
  //         setPlanOfAction(response.data?.data?.plan_d_actions);
  //         setLeftBtn(false);
  //         setDecisions(
  //           response?.data?.data?.step_decisions?.map((item) => item).join("\n")
  //         ); // Take all the decisions from the steps and join them with a new line and form a single string.
  //         setNotes(
  //           response?.data?.data?.step_notes?.map((item) => item).join("\n")
  //         ); // Take all the decisions from the steps and join them with a new line and form a single string.
  //         setSummaryOfNotes(
  //           response?.data?.data?.step_notes?.map((item) => item).join("\n")
  //         ); // Take all the notes from the steps and join them with a new line and form a single string.
  //       } else {
  //         toast.error("Échec de la récupération du rapport");
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       // console.log("error", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   getMeetingByID();
  //   // fetchStepNotes();
  // }, [meetingId]);
  const [allMeetings, setAllMeetings] = useState([]);
  console.log("all meetings length", allMeetings.length);
  // const userId = sessionStorage.getItem('user_id')
  const getMeetingByID = async () => {
    try {
      setLoading(true);
      const token = sessionStorage.getItem("token");
      const REQUEST_URL = `${API_BASE_URL}/get-public-meetings/${meetingId}/${objective}`;

      const response = await axios.get(REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status) {
        // setParticipants(response.data?.data?.participants);

        const meetings = response.data?.data;
        setAllMeetings(meetings);
        const meetingByIdIndex = meetings?.findIndex(
          (meeting) => meeting.id.toString() === meetId
        );
        console.log("meetingByIndex", meetingByIdIndex);
        // const matchingMeeting = meetings?.find(meeting => meeting.id.toString() === meetingId);
        if (meetingByIdIndex !== -1) {
          setCurrentIndex(meetingByIdIndex);
          setMeetingData(meetings[meetingByIdIndex]);
        }
      } else {
        toast.error("Échec de la récupération du rapport");
        setLoading(false);
      }
    } catch (error) {
      // console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMeetingByID();
  }, []);
  const handleNext1 = () => {
    setCurrentIndex(currentIndex + 1);
    setMeetingData(allMeetings[currentIndex + 1]);
  };

  const handlePrevious1 = () => {
    if (currentIndex > 0) {
      // setCurrentIndex(prevIndex => (prevIndex - 1 + meetingData.length) % meetingData.length);
      setCurrentIndex(currentIndex - 1);
      // setAllMeetings(currentIndex-1);
      setMeetingData(allMeetings[currentIndex - 1]);
    }
  };
  // for fetching the profile data of user who created meeting.
  const [user, setUser] = useState();
  console.log("user", user);
  useEffect(() => {
    const getUserByID = async () => {
      try {
        const token = sessionStorage.getItem("token");
        // const userId = meetingData?.user_id;
        const userId = sessionStorage.getItem("user_id");
        const REQUEST_URL = `${API_BASE_URL}/users/${userId}`;
        const response = await axios.get(REQUEST_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response) {
          setUser(response.data?.data);
          setProfileData(response.data.data);
          setUserProfile(response.data.data?.image);
        } else {
          // toast.error("Échec de la récupération du rapport");
          setLoading(false);
        }
      } catch (error) {
        // console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    getUserByID();
  }, []);

  // HANDLERS;
  function formatDate(inputDate) {
    if (inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${day}/${month}/${year}`;
      }
    }
    return inputDate;
  }
  const formattedDate = formatDate(meetingData?.date);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "You have unsaved changes. Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [showParticipantModal, setShowParticipantModal] = useState(false);
  const openProfileLink = () => {
    console.log("clicked");
    setShowParticipantModal(true);
    // if (profileData.link) {
    //   const isCompleteUrl = /^https?:\/\//i.test(profileData.image);
    //   const url = isCompleteUrl
    //     ? profileData.image
    //     : `http://${profileData.link}`;
    //   window.open(url, "_blank");
    // }
  };

  // const handleNavigateLink = () =>{
  //    if (user?.link) {
  //     const url = `http://${user.link}`
  //     window.open(url, "_blank");
  //   }
  // }
  const handleNavigateLink = () => {
    if (user?.link) {
      let url = user.link.toLowerCase();
      if (!/^https?:\/\//i.test(url)) {
        url = `http://${url}`;
      }
      window.open(url, "_blank");
    }
  };

  const realStartTime = meetingData?.real_start_time;
  const realEndTime = meetingData?.real_end_time;
  let realPlayDuration = 0;
  // Ensure both real start time and real end time are defined
  if (realStartTime && realEndTime) {
    // Split the time strings into hours, minutes, and seconds
    const [startHours, startMinutes, startSeconds] = realStartTime
      .split(":")
      .map(Number);
    const [endHours, endMinutes, endSeconds] = realEndTime
      .split(":")
      .map(Number);

    // Calculate the difference in seconds
    let secondsDifference = (endHours - startHours) * 3600;
    secondsDifference += (endMinutes - startMinutes) * 60;
    secondsDifference += endSeconds - startSeconds;

    // Convert seconds to minutes
    const realPlayDurationMinutes = secondsDifference / 60;
    realPlayDuration = realPlayDurationMinutes;
  }
  const totalScheduledDuration = meetingData?.total_time;
  const clockColor =
    meetingData?.real_start_time <= meetingData?.start_time ? "green" : "red";
  const finClockColor =
    realPlayDuration > totalScheduledDuration ? "red" : "green";

  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleItemClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
  };

  const [showModal, setShowModal] = useState(false);
  const [participantData, setParticipantData] = useState({});
  const handleNavigate = async (id) => {
    const token = sessionStorage.getItem("token");
    try {
      // setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/participants/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setShowModal(true);
        setParticipantData(response?.data?.data.participant);
      }
    } catch (error) {
      // console.log("error message", error);
    } finally {
      setLoading(false);
    }
  };
  const [meetingIndex, setMeetingIndex] = useState(0);
  const [image, setImage] = useState(null);

  const handleLeft = async (id) => {
    let previousIndex;
    if (meetingIndex > 0) {
      previousIndex = meetingIndex - 1;
      setMeetingIndex(previousIndex);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/meetingViewDestination/${id}/${meetingData?.objective}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          console.log("previous Index-->", previousIndex);
          const meetings = response.data.data;
          setLen(meetings?.length);
          // setMeetingId(meetings[previousIndex-1].id);
          setMeetingData(meetings[previousIndex]);
        }
      } catch (error) {
        console.log("error: " + error);
      }
    }
    if (previousIndex === 0) {
      try {
        const REQUEST_URL = `${API_BASE_URL}/meetingView/${meetingId}`;
        const response = await axios.get(REQUEST_URL, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        const _data = response.data.data;
        setLen(response?.data?.data?.length);
        if (_data) {
          setImage(response.data.data?.user?.image);
          setPic(response.data.data?.user?.enterprise.logo);
          setMeetingData(response.data?.data);
          setMeetingCount(_data?.destinationCount);
          setLeftBtn(false);
          // setMeetingId(response.data.data?.id);
          setParticipants(response.data?.data?.participants);
          setPlanOfAction(response.data?.data?.plan_d_actions);
          setDecisions(
            response?.data?.data?.step_decisions?.map((item) => item).join("\n")
          );
          setNotes(
            response?.data?.data?.step_notes?.map((item) => item).join("\n")
          );
          setSummaryOfNotes(
            response?.data?.data?.step_notes?.map((item) => item).join("\n")
          );
        }
      } catch (error) {
        console.log("error while fetching get meeting by id", error);
      }
    }
  };

  const [id, setId] = useState(null);
  const handleRight = async (id) => {
    setLeftBtn(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/meetingViewDestination/${id}/${meetingData.objective}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status) {
        const meetings = response.data.data;
        console.log("meetings", meetings);
        console.log("meetings length", meetings.length);
        // setId(meetings.id)
        setLen(meetings?.length);
        let nextIndex = meetingIndex;
        if (nextIndex < meetings.length) {
          // setMeetingId(response.data.data[nextIndex].id);
          setMeetingData(meetings[nextIndex]);
          if (meetings.length >= 0) {
            setMeetingIndex(nextIndex + 1);
          }
        } else {
          console.log("No more meetings available");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [show, setShow] = useState(false);
  const stepModal = () => {
    setShow(true);
  };

  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showDecisionModal, setShowDecisionModal] = useState(false);
  const [showPlanActionModal, setShowPlanActionModal] = useState(false);

  const notesModal = () => {
    setShowNoteModal(true);
  };
  const decisionsModal = () => {
    setShowDecisionModal(true);
  };

  const planActionModal = () => {
    setShowPlanActionModal(true);
  };

  // Extract participants from data
  //  const participants = data[0]?.participants;

  // Extract all participant_ids from plan_d_actions
  const participantIds = meetingData?.plan_d_actions?.map(
    (action) => action.participant_id
  );

  // Add console logs for debugging
  console.log("Plan D Actions:", meetingData?.plan_d_actions);
  console.log("Participants:", meetingData?.participants);
  console.log("Participant IDs:", participantIds);

  // Filter the participants based on participantIds
  const filteredParticipants = meetingData?.participants?.filter((user) =>
    participantIds.includes(user.id)
  );

  // Add console log for filtered participants
  console.log("Filtered Participants:", filteredParticipants);
  return (
    <div className="presentation">
      {loading ? (
        <Spinner
          animation="border"
          role="status"
          className="center-spinner"
        ></Spinner>
      ) : (
        <main className="container-fluid py-5">
          <section className="d-flex justify-content-between main-presen">
            <div className="d-flex justify-content-center align-items-center mb-2">
              <div>
                {pic === null ? (
                  <img
                    src="/Assets/logo2.png"
                    // className="img-fluid"
                    style={{ height: "9rem", mixBlendMode: "multiply" }}
                    alt="tektime"
                  />
                ) : (
                  <img
                    src={Assets_URL + pic}
                    className="user-img"
                    // style={{ height: "9rem", mixBlendMode: "multiply" }}
                    alt="tektime"
                    onErrorCapture={(e) => {
                      e.target.src = "/Assets/logo2.png";
                    }}
                  />
                )}
              </div>
              {currentIndex > 0 && allMeetings.length - 1 !== 0 && (
                <div
                  className="position-relative"
                  style={{ left: "14rem", cursor: "pointer" }}
                  onClick={() => handlePrevious1()}
                >
                  <FaAngleLeft size={30} />
                </div>
              )}
            </div>
            <div className="text-center presen-title mb-2">
              <div>
                <h2>{meetingData?.objective} </h2>
              </div>
              <div>
                {formattedDate} |{" "}
                {(() => {
                  const startTime =
                    meetingData?.real_start_time === null
                      ? "0h0"
                      : meetingData.real_start_time;
                  const endTime =
                    meetingData?.real_end_time === null
                      ? "0h0"
                      : meetingData.real_end_time;
                  const totalHHMM = moment.duration(
                    moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))
                  );
                  return totalHHMM.hours() + "h" + totalHHMM.minutes();
                })()}{" "}
              </div>
              <div>
                <h4>{meetingData?.title} </h4>
              </div>
            </div>

            <div className="card card1 p-3 text-center mb-2">
              <div>
                <div className="card-title">
                  <h6>{t("presentation.statistics")}</h6>
                </div>
                <div className="d-flex justify-content-center gap-5">
                  <div>
                    <span className="start">{t("presentation.start")}</span>{" "}
                    <br />
                    <LuClock7 size={20} color={clockColor} /> <br />
                    <span className="time">
                      {moment(meetingData?.real_start_time, "HH:mm").format(
                        "HH[h]mm"
                      )}
                    </span>
                  </div>{" "}
                  <div>
                    <span className="start">{t("presentation.end")}</span>{" "}
                    <br />
                    <LuClock9 size={20} color={finClockColor} /> <br />
                    <span className="time">
                      {moment(meetingData?.real_end_time, "HH:mm").format(
                        "HH[h]mm"
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {/* {meetingCount > 0 && meetingIndex !== len && ( */}
              {allMeetings.length - 1 !== currentIndex && (
                <div
                  style={{
                    right: "21rem",
                    position: "absolute",
                    top: "3.1rem",
                    cursor: `${
                      meetingIndex !== len ? "pointer" : "not-allowed"
                    }`,
                  }}
                  // onClick={() => handleRight(meetingData?.id)}
                  onClick={() => handleNext1()}
                >
                  <FaAngleRight
                    size={30}
                    color={meetingIndex !== len ? "black" : "#8f8f8f"}
                  />
                </div>
              )}
            </div>
          </section>
          <br />
          <section className="row py-1">
            <div className="col-md-6 mb-2">
              <div className="card card2 p-3 ">
                <div>
                  <h5>{t("presentation.agenda")}</h5>
                </div>
                {/* <div className=""> */}
                <textarea
                  type="text"
                  name="description"
                  value={meetingData?.description}
                  // className="form-control "
                  rows={5}
                  placeholder="contexte"
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="card card2 p-3 ">
                <div>
                  <h5>{t("presentation.participants")}</h5>
                </div>
                <div className="text-start mb-2">
                  <img
                    // src={`${Assets_URL}${image}` || "/Assets/avatar.jpeg"}
                    // src={
                    //   imageError
                    //     ? "/Assets/avatar.jpeg"
                    //     : `${Assets_URL}/${image}`
                    // }
                    src={
                      !meetingData?.user?.image
                        ? "/Assets/avatar.jpeg"
                        : `${Assets_URL}/${meetingData?.user?.image}`
                      // : `${Assets_URL}/${image}`
                    }
                    style={{ cursor: "pointer" }}
                    alt="profile"
                    onClick={openProfileLink}
                    className="profileimg"
                    // onError={(e) => {
                    //   setImageError(true);
                    // }}
                  />
                  <b>
                    {" - "}
                    {meetingData?.user?.name} &nbsp;
                    {meetingData?.user?.last_name}
                    {" - "}
                    {Array.isArray(meetingData?.enterprise)
                      ? profileData.enterprise?.map((enterprise) => (
                          <>{enterprise?.name}</>
                        ))
                      : meetingData?.user?.enterprise?.name}
                    {" - "}
                    {/* {meetingData?.user?.teams?.map((team) => (
                      <>{team?.name} </>
                    ))} */}
                    {/* {meetingData?.user?.teams.length > 0 && "-"} */}
                    {meetingData?.user?.post}{" "}
                  </b>
                </div>
                <div className="resume">
                  <ul className="list-unstyled">
                    {meetingData?.participants
                      ?.filter(
                        (item) =>
                          item.first_name !== null || item.last_name !== null
                      )
                      ?.filter(
                        (item) => item.isCreator !== 1 // Filter out participants with isCreator equal to 1
                      )
                      ?.map((item, index) => (
                        <li key={item.id} className="d-flex align-items-center">
                          {" "}
                          <IoIosCheckmarkCircleOutline
                            // color={selectedIndices.includes(index) ? "red" : "green"}
                            // color={
                            //   participants[index]?.attandance === 0
                            //     ? "green"
                            //     : "red"
                            // }
                            color={item?.attandance === 0 ? "green" : "red"}
                            size={"16px"}
                            onClick={() => handleItemClick(index)}
                          />{" "}
                          &nbsp;
                          <input
                            style={{ cursor: "pointer" }}
                            readOnly
                            type="text"
                            name="participant"
                            value={`${item.first_name} ${item.last_name}`}
                            onClick={() => handleNavigate(item.id)}
                          />
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/* 2nd GRID STARTS */}
          <section className="row py-1">
            <div className="col-md-6 mb-2">
              <div className="card card2 p-3 ">
                <div className="d-flex align-items-center mb-3">
                  <div
                    className="mr-3"
                    style={{
                      cursor: "pointer",
                      // background: "#007BFF",
                      borderRadius: "20px",
                      padding: "3px 6px",
                      // color: "white",
                    }}
                    onClick={() => {
                      navigate("/step-details/" + meetingData?.id);
                    }}
                  >
                    <IoMdEye
                      color="black"
                      size={18}
                      style={{
                        margin: "2px",
                      }}
                    />
                  </div>
                  <div className="text-center flex-grow-1">
                    <h5>{t("presentation.summary")}</h5>
                  </div>
                </div>
                {/* <div className="resume"> */}
                <ol className="resume">
                  {meetingData?.steps?.map((item, index) => (
                    <li key={index}>
                      <input type="text" name="title" value={item.title} />
                    </li>
                  ))}
                </ol>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="card card2 p-3 ">
                <div className="d-flex align-items-center mb-3">
                  <div
                    className="mr-3"
                    style={{
                      cursor: "pointer",
                      // background: "#007BFF",
                      borderRadius: "20px",
                      padding: "3px 6px",
                      // color: "white",
                    }}
                    onClick={notesModal}
                  >
                    <IoMdEye
                      color="black"
                      size={18}
                      style={{
                        margin: "2px",
                      }}
                    />
                  </div>
                  <div className="text-center flex-grow-1">
                    <h5>{t("presentation.notes")}</h5>
                  </div>
                </div>
                <div className="resume">
                  {/* <div
                   dangerouslySetInnerHTML={{ __html: meetingData?.steps?.map(item => (
                    <div key={item.id}>
                      <p>Title: {item.title}</p>
                      <p>Note: {item.note}</p>
                    </div>
                  )) }}
                  />
                  {/* <textarea
                    type="text"
                    name="step_notes"
                    value={(() => {
                      let filteredNotes = [];
                      meetingData?.step_notes?.map((note) => {
                        if (note != null) {
                          filteredNotes.push(note);
                        }
                      });
                      return filteredNotes.join("\n");
                    })()}
                    className="form-control "
                    rows={5}
                    placeholder="Summary of Notes Taken on each step"
                  /> */}
                  {/* </div> */}
                  {meetingData?.steps?.map((step, index) => (
                    <div
                      key={index}
                      className="d-flex flex-column"
                      style={{ paddingLeft: "10px" }}
                      // onClick={() => setNotesModalShow(true)}
                    >
                      <div className="d-flex gap-1 align-items-center">
                        <span>{step?.title}</span>
                      </div>

                      {/* <div dangerouslySetInnerHTML={{ __html: step.title_with_notes?.replace(/title:|note:/gi, '') }} /> */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: step?.note === null ? "" : 
                          step?.note?.replace(
                            /\*\*(.*?)\*\*/g,
                            '<b style="font-size:16px"> $1</b> <br>'
                          )
                          .replace(/(\d+)\./g, "<br>$1. ") // for numbers
                          .replace(/(\.|\))\s*-?\s*/g, "$1"),
                          // step?.note,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          {/* 3rd GRID */}
          <section className="row py-1">
            <div className="col-md-6 mb-2">
              {/* Plan of Action */}
              <div className="card card2 p-3 table-container ">
                <div className="d-flex align-items-center mb-3">
                  {/* <div
                    className="mr-3"
                    style={{
                      cursor: "pointer",
                      // background: "#007BFF",
                      borderRadius: "20px",
                      padding: "3px 6px",
                      // color: "white",
                    }}
                    onClick={planActionModal}
                  >
                    <IoMdEye
                      color="black"
                      size={18}
                      style={{
                        margin: "2px",
                      }}
                    />
                  </div> */}
                  <div className="text-center flex-grow-1">
                    <h5 className="card-title">
                      {t("presentation.planDActions")}
                    </h5>
                  </div>
                </div>
                {/* <h5 className="card-title">{t("presentation.planDActions")}</h5> */}

                <div className="cardbody resume">
                  <div className=" row  subtitle  text-body-secondary">
                    <div className="col-md-2 ">
                      <span>{t("presentation.order")}</span>
                    </div>
                    <div className=" col-md-3 ">
                      <span>{t("presentation.action")}</span>
                    </div>
                    <div className="col-md-2 ">
                      <span>{t("presentation.carrier")}</span>
                    </div>
                    <div className="col-md-2">
                      <span>{t("presentation.dueDate")}</span>
                    </div>
                    <div className=" col-md-2">
                      <span>{t("presentation.status")}</span>
                    </div>
                  </div>
                  {meetingData?.plan_d_actions?.map((action, index) => {
                    return (
                      <div
                        className="row mt-3"
                        style={{ borderBottom: "1px solid #ccc" }}
                        key={index}
                      >
                        <div className="col-md-2">
                          <select
                            className="form-select form-select-sm"
                            readOnly
                          >
                            {Array.from({ length: 10 }).map((_, i) => (
                              <option value={i + 1}>{i + 1}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-3">
                          <textarea
                            value={action.action}
                            name="action"
                            rows={2}
                            placeholder="Action"
                            maxLength={100}
                            className="wrapped-textarea"
                          />
                        </div>

                        <div className="col-md-2">
                          <select
                            disabled
                            readOnly
                            className="form-select form-select-sm"
                            name="participant_id"
                            value={action.participant_id}
                          >
                            <option value={""}>select participant</option>
                            {meetingData?.participants
                              ?.filter(
                                (user) => user.id === action?.participant_id
                              )
                              .map((user, index) => (
                                <option key={index} value={user?.id}>
                                  {user.first_name} {user.last_name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-md-2">
                          <input
                            type="text"
                            value={Math.floor(action.action_days)}
                            name="action_days"
                          />
                        </div>

                        <div className="col-md-2">
                          <select
                            readOnly
                            disabled
                            className="form-select form-select-sm"
                            value={action.status}
                            name="status"
                          >
                            <option value={""}>Status</option>
                            <option value={"Todo"}>
                              {t("presentation.todo")}
                            </option>
                            <option value={"InProgress"}>
                              {t("presentation.inprogress")}
                            </option>
                            <option value={"Finished"}>
                              {t("presentation.finished")}
                            </option>
                          </select>
                        </div>

                        {/* <div className="col-1">
                          <button
                            className="btndel"
                          >
                            <AiFillDelete size={"15px"} color="red" />
                          </button>
                        </div> */}
                      </div>
                    );
                  })}
                </div>

                <div className="d-flex justify-content-center text-center mt-2 gap-2">
                  {/* ADD PLAN D ACTION BUTTON */}
                  <div></div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              {/* DECISIONS */}
              <div className="card card2 p-3 ">
                <div className="d-flex align-items-center mb-3">
                  <div
                    className="mr-3"
                    style={{
                      cursor: "pointer",
                      borderRadius: "20px",
                      padding: "3px 6px",
                    }}
                    onClick={decisionsModal}
                  >
                    <IoMdEye
                      color="black"
                      size={18}
                      style={{
                        margin: "2px",
                      }}
                    />
                  </div>
                  <div className="text-center flex-grow-1">
                    <h5>{t("presentation.decisions")}</h5>
                  </div>
                </div>
                <div className="resume">
                  <div
                    // dangerouslySetInnerHTML={{
                    //   __html: meetingData?.step_decisions
                    // }}
                    dangerouslySetInnerHTML={{
                      __html: (() => {
                        let filteredDecisions = [];
                        meetingData?.step_decisions?.map((decision) => {
                          if (decision != null) {
                            filteredDecisions.push(decision);
                          }
                          return decision;
                        });
                        return filteredDecisions.join("\n");
                      })(),
                    }}
                  />
                  {/* <textarea
                    // disabled
                    readOnly
                    type="text"
                    name="step_decisions"
                    value={(() => {
                      let filteredDecisions = [];
                      meetingData?.step_decisions?.map((note) => {
                        if (note != null) {
                          filteredDecisions.push(note);
                        }
                      });
                      return filteredDecisions.join("\n");
                    })()}
                    rows={5}
                    placeholder="Summary of Decisions Taken"
                  /> */}
                </div>
              </div>
            </div>
          </section>
          <div className="text-center d-flex gap-3 justify-content-center pres-btn">
            <a
              href="https://www.tektime.fr/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              En savoir plus sur TekTIME
            </a>
          </div>
          <Modal
            show={showNoteModal}
            onHide={() => setShowNoteModal(false)}
            centered
            className="modal-lg"
          >
            <Modal.Body>
              <div className="modal-header">
                <h5>{t("presentation.notes")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowNoteModal(false)}
                ></button>
              </div>
              <div
                className="d-flex flex-column gap-1"
                style={{ marginLeft: "17px", marginTop: "15px" }}
              >
                {meetingData?.steps
                  ?.filter((item) => item !== null)
                  .map((item, index) => {
                    console.log("item", item);
                    return (
                      <>
                        <div>
                          <span style={{fontWeight:'bolder'}}>{item.title}</span>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{ __html:   item?.note?.replace(
                              /\*\*(.*?)\*\*/g,
                              '<b style="font-size:16px"> $1</b> <br>'
                            )
                            .replace(/(\d+)\./g, "<br>$1. ") // for numbers
                            .replace(/(\.|\))\s*-?\s*/g, "$1"),}}
                          key={index}
                        >
                          {/* <p>{item}</p> */}
                        </div>
                      </>
                    );
                  })}
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={showDecisionModal}
            centered
            onHide={() => setShowDecisionModal(false)}
            className="modal-lg"
          >
            <Modal.Body>
              <div className="modal-header">
                <h5>{t("presentation.decisions")}</h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowDecisionModal(false)}
                ></button>
              </div>

              {meetingData?.step_decisions?.length > 0 &&
                meetingData?.step_decisions
                  .filter((item) => item !== null)
                  .map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <Stack
                            direction="row"
                            align="center"
                            gap={3}
                            className="my-3"
                          >
                            <div className="col-md-12">
                              <div className="card">
                                <div className="card-body">
                                  <div className="cardbody">
                                    <div className="row subtitle card2 py-2 px-3">
                                      <div className="col-md-1 text-start obj">
                                        <span>{index + 1}</span>
                                      </div>
                                      <div className=" col-md-3 ">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item,
                                          }}
                                          key={index}
                                        >
                                          {/* <p>{item}</p> */}
                                        </div>
                                        {/* <span>{item}</span> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Stack>
                        </div>
                      </>
                    );
                  })}
            </Modal.Body>
          </Modal>
          {/* <Modal show={show} className={`modal-xl`}>
            <Modal.Body>
              <div className="modal-header">
                <h5>{t("presentation.summary")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </div>
              <Chart meetingId={meetingData?.id} />
            </Modal.Body>
          </Modal> */}
          <Modal show={showPlanActionModal} className={`modal-xl`}>
            <Modal.Body>
              <div className="modal-header">
                <h5>{t("presentation.planDActions")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowPlanActionModal(false)}
                ></button>
              </div>
              <PlanDChart meetingId={meetingData?.id} />
            </Modal.Body>
          </Modal>
          {showModal && (
            <Modal
              show
              className="lg"
              centered
              onHide={() => setShowModal(false)}
            >
              <Modal.Header closeButton></Modal.Header>
              <div className="profile">
                {loading ? (
                  <>
                    <Spinner
                      animation="border"
                      role="status"
                      className="center-spinner"
                    ></Spinner>
                  </>
                ) : (
                  <div className="card" style={{ padding: "10px 96px" }}>
                    <div className="justify-content-center">
                      <div className="mb-3">
                        <label className="form-label">
                          <h6>{t("profile.name")}</h6>
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          placeholder={t("profile.name")}
                          value={participantData.first_name}
                          className="form-control"
                          // readOnly={window.location.href.includes("/participant")}
                          readOnly
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          <h6>{t("profile.fname")}</h6>
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          placeholder={t("profile.fname")}
                          value={participantData.last_name}
                          className="form-control"
                          // readOnly={window.location.href.includes("/participant")}
                          readOnly
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          <h6>Email</h6>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          value={participantData.email}
                          // readOnly={window.location.href.includes("/participant")}
                          readOnly
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label">
                          <h6>{t("profile.post")}</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="post"
                          placeholder={t("profile.post")}
                          value={participantData.post}
                          // readOnly={window.location.href.includes("/participant")}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
          )}
          {showParticipantModal && (
            <Modal
              show
              size="xl"
              centered
              onHide={() => setShowParticipantModal(false)}
            >
              <Modal.Header closeButton></Modal.Header>
              <div className="profile">
                {loading ? (
                  <>
                    <Spinner
                      animation="border"
                      role="status"
                      className="center-spinner"
                    ></Spinner>
                  </>
                ) : (
                  <div className="profile">
                    <div className="container-fluid pb-5">
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <div className="image-uploader mt-4">
                            <div
                              className={`image-preview`}
                              // onMouseEnter={handleHover}
                              // onMouseLeave={handleMouseLeave}
                              // onClick={handleButtonClick}
                            >
                              {user.image ? (
                                <img
                                  src={`${Assets_URL}/${user?.image}`}
                                  alt="Uploaded"
                                />
                              ) : (
                                <>
                                  <div className="avatar">
                                    <AiOutlineUser size={200} color="grey" />
                                  </div>
                                  {/* {isHovered && !isEditing && (
                          <div className="upload-text">Click to Upload</div>
                        )} */}
                                </>
                              )}
                              {/* {isHovered && !isEditing && (
                      <div className="upload-text">Click to Upload</div>
                    )} */}
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              // onChange={handleImageUpload}
                              // ref={fileInputRef}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3  d-flex flex-column justify-content-center  ">
                          <div className="mb-3 ">
                            <label className="form-label">
                              <h6>{t("profile.name")}</h6>
                            </label>
                            <input
                              placeholder={t("profile.name")}
                              type="text"
                              className="form-control"
                              name="name"
                              value={
                                user.name === "null" ? " " : user.name || " "
                              }
                              // onChange={(e) =>
                              //   setUserData({ ...userData, name: e.target.value })
                              // }
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              <h6>{t("profile.fname")}</h6>
                            </label>
                            <input
                              placeholder={t("profile.fname")}
                              type="text"
                              className="form-control"
                              name="name"
                              value={
                                user.last_name === "null"
                                  ? " "
                                  : user.last_name || " "
                              }
                              // onChange={(e) =>
                              //   setUserData({ ...userData, last_name: e.target.value })
                              // }
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              <h6>{t("profile.nickname")}</h6>
                            </label>
                            <input
                              placeholder={t("profile.nickname")}
                              type="text"
                              className="form-control"
                              name="login"
                              value={
                                user.nick_name === "null"
                                  ? `${t("profile.nickname")}`
                                  : user.nick_name || " "
                              }
                              // onChange={(e) =>
                              //   setUserData({
                              //     ...userData,
                              //     nick_name: e.target.value,
                              //   })
                              // }
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              <h6>Email</h6>
                            </label>
                            <input
                              type="text"
                              readOnly
                              className="form-control"
                              name="email"
                              value={
                                user.email === "null" ? " " : user.email || " "
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3  d-flex flex-column justify-content-center  ">
                          <div className="mb-3">
                            <label className="form-label">
                              <h6>{t("profile.company")}</h6>
                            </label>
                            <input
                              type="text"
                              readOnly
                              className="form-control"
                              name="enterprise_id"
                              value={
                                user?.enterprise?.name === "null"
                                  ? " "
                                  : user?.enterprise?.name || " "
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              <h6>{t("profile.team")}</h6>
                            </label>
                            <Select
                              className="react-select"
                              id="teamSelect"
                              isMulti
                              name="team_id"
                              // isDisabled={getUserRoleID() === 4 ? true : false}
                              isDisabled
                              // options={
                              //   Array.isArray(teamOptions) && teams?.length > 0
                              //     ? teamOptions
                              //     : []
                              // }
                              value={user?.teams?.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))}
                              // onChange={handleSelectInputChange}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              <h6>{t("profile.job")}</h6>
                            </label>
                            <input
                              placeholder={t("profile.job")}
                              type="text"
                              value={
                                user?.post === "null" ? " " : user?.post || " "
                              }
                              className="form-control"
                              name="post"
                              // onChange={(e) =>
                              //   setUserData({ ...userData, post: e.target.value })
                              // }
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              <h6>{t("profile.link")}</h6>
                            </label>
                            <input
                              placeholder={t("profile.link")}
                              type="text"
                              className="form-control"
                              value={
                                user.link === "null" ? "" : user.link || ""
                              }
                              name="link"
                              style={{ cursor: "pointer" }}
                              onClick={handleNavigateLink}
                              // onChange={(e) =>
                              //   setUserData({ ...userData, link: e.target.value })
                              // }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
          )}
        </main>
      )}
    </div>
  );
};

export default Presentation;
