import React from "react";
import { Card } from "react-bootstrap";
import { Assets_URL } from "../../Apicongfig";
const ParticipantCard = ({ data }) => {
  return (
    <>
      <div className="row">
        {data?.map((item, index) => {
          if (item.isCreator === 1) {
            return null; 
          }
          return (
            <div className="col-md-3" key={index}>
              <Card className="mt-4 participant-card">
                <Card.Body>
                  <div className="d-flex justify-content-center">
                    <div className="profile-logo">
                      {item.image ? (
                        <Card.Img
                          className="user-img"
                          src={`${Assets_URL}/${item?.image}`}
                        />
                      ) : item.participant_image ? (
                        <Card.Img
                          className="user-img"
                          src={item.participant_image}
                        />
                      ) : (
                        <Card.Img
                          className="user-img"
                          src="/Assets/avatar.jpeg"
                        />
                      )}
                      <Card.Img
                        className="logout-icon"
                        src="/Assets/Avatar_company.svg"
                        height="20px"
                        width="20px"
                        alt="tektime"
                      />
                    </div>
                  </div>

                  <Card.Title className="text-center mt-4 card-heading">
                    {item.first_name + " " + item.last_name}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 card-subtext">
                    {item.post}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ParticipantCard;
