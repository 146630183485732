import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./style/main.scss";
import { HeaderTitleProvider } from "./context/HeaderTitleContext";
import { DraftMeetingsProvider } from "./context/DraftMeetingContext";
import { TotalTimeProvider } from "./context/TotalTimeContext";
import { StepProvider } from "./context/stepContext";
import { StepProvider1 } from "./context/Step";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UserProvider } from "./context/UserContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="50204800014-ldiqa8nnkv4d8jukk59d6qkgpi0bsljt.apps.googleusercontent.com">
    <StepProvider1>
      <UserProvider>
        <StepProvider>
          <TotalTimeProvider>
            <DraftMeetingsProvider>
              <HeaderTitleProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </HeaderTitleProvider>
            </DraftMeetingsProvider>
          </TotalTimeProvider>
        </StepProvider>
      </UserProvider>
    </StepProvider1>
  </GoogleOAuthProvider>
);
